import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import storage from "../../../utils/local-storage"
import countriesWithStates from "../../../utils/county-with-states"
import { useEffect } from "react"
import { API_URL } from "../../../utils/Constants"
import { destructJsonObj } from "../../../utils/other-utils"

const re =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const validationSchema = yup.object({
  business_name: yup.string().required(),
  website: yup.lazy(url =>
    !url ? yup.string() : yup.string().matches(re, "URL is not valid")
  ),
  country: yup.string().required("Country is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  line1: yup.string().required("Address is required"),
  line2: yup.string(),
  zipcode: yup
    .string("Zip/Postal code is required")
    .required("Zip/Postal code is required"),
})

const AddressInformation = ({ onConfirm, onPrev, currentStepCount }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...storage.getUserInfo(storage.ADDRESS_KEY),
    },
    resolver: yupResolver(validationSchema),
  })

  const [states, setStates] = useState([])
  const [customErrors, setCustomErrors] = useState({})

  const onSubmit = data => {
    setCustomErrors({})
    if (isValid) {
      const newData = {
        ...data,
        street: `${data.line1}~${data.line2}`,
      }
      if (bypassValidation(data)) {
        storage.setUserInfo(storage.ADDRESS_KEY, data)
        onConfirm(currentStepCount + 1)
      } else {
        validateAddress(newData)
      }
    }
  }

  const bypassValidation = data => {
    const country = data.country
    if (["China","Vietnam"].includes(country)) return true

    return false
  }

  const validateAddress = async data => {
    const countryCode = countriesWithStates.filter(
      st => data.country === st.country
    )[0].code

    fetch(`${API_URL}validate/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        business_name: data.business_name,
        website: data.website,
        street_address: data.street,
        city: data.city,
        country_code: countryCode,
        country_area: data.state,
        postal_code: data.zipcode,
      }),
    })
      .then(res => res.json())
      .then(resData => {
        if (resData?.errors == null) {
          storage.setUserInfo(storage.ADDRESS_KEY, data)
          onConfirm(currentStepCount + 1)
          return
        }
        const customErrors = destructJsonObj(resData.errors)
        setCustomErrors(customErrors)
      })
      .catch(e => {
        console.log(e)
        if (e?.errors == null) return

        const customErrors = destructJsonObj(e.errors)
        console.log(customErrors)
        setCustomErrors(customErrors)
      })
  }

  useEffect(() => {
    const prevCountry = storage.getUserInfo(storage.ADDRESS_KEY)?.country
    if (prevCountry != null) {
      const item = countriesWithStates.filter(
        st => prevCountry === st.country
      )[0]
      setStates(item.states)
    }
  }, [])

  const populateState = e => {
    const country = e.target.value
    const item = countriesWithStates.filter(st => st.country === country)[0]
    setStates(item.states)
  }

  return (
    <div>
      <p className="mb-4">What is the name and location of your business?</p>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          placeholder="Business Name"
          type={"text"}
          {...register("business_name")}
        />
        <p className={"error-text"}>{errors?.business_name?.message}</p>
        <div className="pb-4" />
        <input
          placeholder="Website (Optional)"
          type={"text"}
          {...register("website")}
        />
        <p className={"error-text"}>{errors?.website?.message}</p>
        <div className="pb-4" />
        <div style={{ flex: 1 }} className="mb-4">
          <select
            {...register("country")}
            defaultValue=""
            onChange={e => populateState(e)}
          >
            <option key={0} value="" disabled>
              Select country...
            </option>
            {countriesWithStates.map((item, index) => {
              return (
                <option key={item.country} value={item.country}>
                  {item.country}
                </option>
              )
            })}
          </select>
          <p className={"error-text"}>{errors.country?.message}</p>
          <p className={"error-text"}>{customErrors?.["country_code"]}</p>
        </div>

        <div style={{ flex: 1 }} className="mb-4">
          {states.length <= 0 ? (
            <input placeholder="State" type={"text"} {...register("state")} />
          ) : (
            <select {...register("state")} defaultValue="">
              <option key={0} value="" disabled>
                Select state...
              </option>
              {states.map((state, index) => {
                return (
                  <option key={index + 1} value={state}>
                    {state}
                  </option>
                )
              })}
            </select>
          )}
          {/* <p className={"error-text"}>{errors.state?.message}</p> */}
          <p className={"error-text"}>{customErrors?.["country_area"]}</p>
        </div>

        <input
          placeholder="Address Line 1"
          type={"text"}
          {...register("line1")}
        />
        <p className={"error-text"}>{errors.line1?.message}</p>
        <p className={"error-text"}>{customErrors?.["street_address"]}</p>

        <div className="pb-4" />

        <input
          placeholder="Address Line 2"
          type={"text"}
          {...register("line2")}
        />
        <p className={"error-text"}>{errors.line2?.message}</p>

        <div className="pb-4" />

        <div style={{ flex: 1 }} className="mb-4">
          <input placeholder="City" type={"text"} {...register("city")} />
          <p className={"error-text"}>{errors.city?.message}</p>
          <p className={"error-text"}>{customErrors?.["city"]}</p>
        </div>

        <div style={{ flex: 1 }} className="mb-4">
          <input
            placeholder="Zip/Postal Code"
            type={"text"}
            {...register("zipcode")}
          />
          <p className={"error-text"}>{errors.zipcode?.message}</p>
          <p className={"error-text"}>{customErrors?.["postal_code"]}</p>
        </div>

        <div className="pb-4" />
        <div className="text-right mb-2">
          <button onClick={() => onPrev(currentStepCount - 1)}>Previous</button>
          <span className="px-1" />
          <button style={{  backgroundColor: "#001a1c"}} type="submit">&nbsp; &nbsp; Next &nbsp; &nbsp;</button>
        </div>
      </form>
    </div>
  )
}

export default  React.memo(AddressInformation)
