const AGREE_TO_TNC_KEY = "userAgree"
const USER_KEY = "userInfo"
const ADDRESS_KEY = "userAddress"
const BILLING_KEY = "userBilling"
const HERE_ABOUT_US_KEY = "aboutUs"

const setUserInfo = (key, userData) => {
  localStorage.setItem(key, JSON.stringify(userData))
}

const getUserInfo = key => {
  
  const user = localStorage.getItem(key)
  if (user === undefined || user === null || user === "" || user === {}) {
    return null
  }

  return JSON.parse(localStorage.getItem(key))
}

const reset = () => {
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(ADDRESS_KEY)
  localStorage.removeItem(BILLING_KEY)
  localStorage.removeItem(HERE_ABOUT_US_KEY)
  localStorage.removeItem(AGREE_TO_TNC_KEY)
}

export default {
  setUserInfo,
  getUserInfo,
  reset,
  AGREE_TO_TNC_KEY,
  USER_KEY,
  ADDRESS_KEY,
  BILLING_KEY,
  HERE_ABOUT_US_KEY,
}
