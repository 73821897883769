import React, { useState, useEffect } from "react"
import storage from "../../../utils/local-storage"

const options = ["Google", "Marketing email", "Colleague", "Other"]

const AlignerBaseInfo = ({ onConfirm, onPrev, currentStepCount }) => {
  const [selectedOption, setSelectedOption] = useState({
    isOther: false,
    selected: "",
    otherText: "",
  })

  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setSelectedOption({
      isOther: storage.getUserInfo(storage.HERE_ABOUT_US_KEY)?.isOther ?? false,
      selected: storage.getUserInfo(storage.HERE_ABOUT_US_KEY)?.selected ?? "",
      otherText:
        storage.getUserInfo(storage.HERE_ABOUT_US_KEY)?.otherText ?? "",
    })
  }, [])

  const onSubmit = () => {
    let validated = true
    if (selectedOption.isOther && selectedOption.otherText.trim() === "") {
      validated = false
    }
    if (selectedOption.isOther === false && selectedOption.selected === "") {
      validated = false
    }

    if (validated === false) {
      setErrorMessage("This is required")
      return
    }

    const data = {
      isOther: selectedOption.isOther,
      selected: selectedOption.selected,
      otherText: selectedOption.otherText.trim(),
    }

    storage.setUserInfo(storage.HERE_ABOUT_US_KEY, data)
    onConfirm(currentStepCount + 1)
  }

  const handleOtherTextChange = e => {
    const inputValue = e.target.value
    setErrorMessage("")
    setSelectedOption({
      ...selectedOption,
      otherText: inputValue,
    })
  }

  const handleOptionChange = e => {
    const selectedValue = e.target.value
    setErrorMessage("")
    if (selectedValue === "Other") {
      setSelectedOption({
        otherText: "",
        selected: selectedValue,
        isOther: true,
      })
    } else {
      setSelectedOption({
        otherText: "",
        selected: selectedValue,
        isOther: false,
      })
    }
  }

  return (
    <div>
      <p className="mb-2">Where did you hear about us?</p>

      <div className="form">
        <select
          onChange={handleOptionChange}
          value={
            selectedOption.other === true ? "Other" : selectedOption.selected
          }
        >
          <option key={0} value="" disabled>
            Choose Option
          </option>
          {options.map((option, index) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          })}
        </select>
        {selectedOption?.isOther === false && (
          <p className={"error-text"}>{errorMessage}</p>
        )}
        <div className="pb-2" />

        {selectedOption?.isOther === true && (
          <>
            <input
              type="text"
              value={selectedOption.otherText}
              onChange={handleOtherTextChange}
              placeholder={"Type your option here..."}
            />
            <p className={"error-text"}>{errorMessage}</p>
          </>
        )}

        <div className="pb-2" />
        <div className="text-right mb-2">
          <button onClick={() => onPrev(currentStepCount - 1)}>Previous</button>
          <span className="px-1" />
          <button style={{ backgroundColor: "#001a1c" }} onClick={onSubmit}>
            &nbsp; &nbsp; Next &nbsp; &nbsp;
          </button>
        </div>
      </div>
    </div>
  )
}

export default  React.memo(AlignerBaseInfo)
