import React, { useMemo, useState, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Swal from "sweetalert2"
import { destructJsonObj, createDateFormat } from "../utils/other-utils"
import { ucFirst } from "../utils/other-utils"

import MultiStepForm from "../components/MultiStepForm"
import storage from "../utils/local-storage"
import { API_URL } from "../utils/Constants"
import GetStartedComponent from "../components/RegisterationSection/GetStartedComponent"
import BasicInformation from "../components/RegisterationSection/BasicInformation"
import AddressInformation from "../components/RegisterationSection/AddressInformation"
import BillingInformation from "../components/RegisterationSection/BillingInformation"
import AlignerBaseInfo from "../components/RegisterationSection/AlignerBaseInfo"
import PasswordInfo from "../components/RegisterationSection/PasswordInfo"
import { Helmet } from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const DoctorRegistrationPage = ({ data, location }) => {
  const params = new URLSearchParams(location.search)
  const email = params.get("email") ?? ""
  const token = params.get("token") ?? ""
  const [activeStepper, setActiveStepper] = useState(0)
  const selectedPassword = useRef("")
  const [loading, setLoading] = useState(false)

  const createDoctor = async (cardDetail, password) => {
    setLoading(true)

    const unstructuredData = {
      ...storage.getUserInfo(storage.USER_KEY),
      ...storage.getUserInfo(storage.ADDRESS_KEY),
      ...storage.getUserInfo(storage.BILLING_KEY),
      ...storage.getUserInfo(storage.HERE_ABOUT_US_KEY),
      password,
    }
 const formData = {
   user: {
     title: unstructuredData?.title,
     first_name: unstructuredData?.first_name,
     last_name: unstructuredData?.last_name,
     username: unstructuredData?.email?.split("@")?.[0],
     email: unstructuredData?.email,
     password: unstructuredData?.password,
     profile: {
       gender: unstructuredData?.gender,
       website: unstructuredData?.website,
       dob: null,
       contact: unstructuredData?.phone,
     },
   },
   tax_type: unstructuredData?.tax_type ? unstructuredData?.tax_type : null,
   vat_no: unstructuredData?.vat_no ? unstructuredData?.vat_no : null,
   is_agreed: true,
   business_name: unstructuredData?.business_name,
   hear_about_us: unstructuredData?.isOther
     ? unstructuredData?.otherText
     : unstructuredData?.selected,
   address: {
     street: unstructuredData?.street,
     city: unstructuredData?.city,
     state: unstructuredData?.state,
     country: unstructuredData?.country,
     country_code: unstructuredData?.country_code,
     zipcode: unstructuredData?.zipcode,
   },
   billing: {
     doctor: null,
     card_no: cardDetail?.number,
     exp_month: cardDetail?.expiry?.split("/")?.[0],
     exp_year: "20" + cardDetail?.expiry?.split("/")?.[1],
     cvc: cardDetail?.cvs,
     default: true,
     name: cardDetail?.name,
     phone: unstructuredData?.phone,
     city: unstructuredData?.billing_city,
     country: unstructuredData?.billing_country,
     state: unstructuredData?.billing_state,
     line1: unstructuredData?.billing_line1,
     line2: unstructuredData?.billing_line2,
     postal_code: unstructuredData?.billing_zipcode,
   },
   facility_token: token,
 }

 const response = await fetch(`${API_URL}doctor/register`, {
   method: "POST",
   headers: { "Content-Type": "application/json" },
   body: JSON.stringify(formData),
 })
 setLoading(false)

 switch (response.status) {
   case 200:
     Swal.fire({
       title: "Success!",
       text: `Your profile has been successfully created! We have sent you a verification email, please click the provided link to finish signing up.`,
       icon: "success",
       allowOutsideClick: false,
       confirmButtonText: "Ok",
       preConfirm: () => {
         storage.reset()
         window.location.href = "/"
       },
     })
     break
   case 400:
     const parsedJson = await response.json()
     const eResponse = destructJsonObj(parsedJson.errors)
     const keys = Object.keys(eResponse)

     let errorArr = []

     if (eResponse && keys.length > 0) {
       if (keys.includes("username") || keys.includes("email"))
         errorArr.push(
           "Account already exists for the entered email. Please try again with a different email address."
         )
       else
         keys.forEach(key => {
           errorArr.push(
             `${ucFirst(key?.replace(/_/g, " "))}: ${eResponse[key]}`
           )
         })
     }

     Swal.fire({
       title: "Error!",
       html: errorArr.join("<br/>"),
       icon: "error",
       confirmButtonText: "Ok",
     })
     break
   default:
     Swal.fire({
       title: "Error!",
       text: "Something went wrong",
       icon: "error",
       confirmButtonText: "Ok",
     })
     break
 }
  }

  const onNextClick = nextStepper => {
    setActiveStepper(nextStepper)
  }

  const onPasswordConfirmation = (nextStepper, password) => {
    selectedPassword.current = password
    setActiveStepper(nextStepper)
  }

  const onBillingNextClick = (nextStepper, billingObj) => {
    createDoctor(billingObj, selectedPassword.current)
  }

  const onPrevClick = prevStepper => {
    setActiveStepper(prevStepper)
  }

  const steps = useMemo(() => {
    return [
      <GetStartedComponent
        currentStepCount={0}
        onConfirm={val => onNextClick(val)}
      />,
      <BasicInformation
        currentStepCount={1}
        email={email}
        onConfirm={val => onNextClick(val)}
        onPrev={val => onPrevClick(val)}
      />,
      <AddressInformation
        currentStepCount={2}
        onConfirm={val => onNextClick(val)}
        onPrev={val => onPrevClick(val)}
      />,
      <AlignerBaseInfo
        currentStepCount={3}
        onConfirm={val => onNextClick(val)}
        onPrev={val => onPrevClick(val)}
      />,
      <PasswordInfo
        currentStepCount={4}
        onConfirm={(val, pw) => onPasswordConfirmation(val, pw)}
        onPrev={val => onPrevClick(val)}
      />,
      <BillingInformation
        currentStepCount={5}
        imgSource={data.poweredByStripe.childImageSharp.fluid}
        onConfirm={(val, billingObj) => onBillingNextClick(val, billingObj)}
        onPrev={val => onPrevClick(val)}
      />,
    ]
  }, [])

  return (
    <Layout>
      <GatsbySeo
        title="description"
        description="Sign Up with AlignerBase: Complete this short form to create your AlignerBase account and submit your first completely free aligner case, with zero charges and no obligations!"
      />
      <Helmet>
        <title>{`Sign up`}</title>
        <meta charSet="utf-8" />
      </Helmet>
      <Seo title={`Sign up`} />

      <div id="doctor-page">
        <div className="form-container">
          {loading === false ? (
            <MultiStepForm
              heading={"Create account"}
              activeStep={activeStepper}
              steps={steps}
            />
          ) : (
            <div className="text-center">
              <br />
              <br />
              <br />
              <br />
              <h4>Creating your account…</h4>
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
        <p className="text-center" style={{ padding: "30px" }}>
          Having trouble signing up, or have any questions? Please send us an
          email at info@alignerbase.com
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    poweredByStripe: file(relativePath: { eq: "powered-by-stripe.webp" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default DoctorRegistrationPage
