import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"
import storage from "../../../utils/local-storage"
import countriesWithStates from "../../../utils/county-with-states"
import Cards from "elt-react-credit-cards"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { API_URL, RECAPTCHA_SITE_KEY } from "../../../utils/Constants"
import { destructJsonObj } from "../../../utils/other-utils"
import "elt-react-credit-cards/es/styles-compiled.css"
import ReCAPTCHA from "react-google-recaptcha";  // Import reCAPTCHA
import image from './resources/Stripe_card.png'

var validationSchema = yup.object().shape({
  vat_no: yup.string(),
  tax_type: yup.string(),
  AorB: yup.bool().when(["vat_no", "tax_type"], {
    is: (vat_no, tax_type) => {
      if (vat_no.length > 0 && tax_type.length > 0) return false
      if (vat_no.length > 0 || tax_type.length > 0) return true
      return false
    },
    then: yup.bool().required("This field is required"),
    otherwise: yup.bool(),
  }),
})

const BillingInformation = ({
  onConfirm,
  onPrev,
  currentStepCount,
  imgSource,
}) => {
  const [sameAddress, setSameAddress] = useState(true)
  const [errors, setErrors] = useState({})
  const [states, setStates] = useState([])
  const [customErrors, setCustomErrors] = useState({})
  const [taxTypeLoading, setTaxTypeLoading] = useState(true)
  const [taxTypes, setTaxTypes] = useState(true)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const [cardDetails, setCardDetails] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  })

  useEffect(() => {
    setSameAddress(
      storage.getUserInfo(storage.BILLING_KEY)?.same_address ?? true
    )

    setTaxTypeLoading(true)
    fetch(`${API_URL}tax-types`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(data => {
        const result = data.data
        const newTaxTypes = Object.keys(result).map(key => {
          return {
            key,
            value: result[key],
          }
        })

        setTaxTypes(newTaxTypes)
      })
      .finally(() => {
        setTaxTypeLoading(false)
      })
  }, [])

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors: formErrors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      expiry: "",
      focus: "",
      name: "",
      billing_city: "",
      tax_type: "",
      vat_no: "",
      ...storage.getUserInfo(storage.ADDRESS_KEY),
    },
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    const prevCountry = storage.getUserInfo(storage.BILLING_KEY)?.country
    if (prevCountry != null) {
      const item = countriesWithStates.filter(
        st => prevCountry === st.country
      )[0]
      setStates(item.states)
    }
  }, [])

  const populateState = e => {
    const country = e.target.value
    const item = countriesWithStates.filter(st => st.country === country)[0]
    setStates(item.states)
  }

  const onSubmit = data => {
    let validated = true
    setCustomErrors({})

    if (
      !(
        cardDetails.cvc &&
        cardDetails.name &&
        cardDetails.number &&
        cardDetails.expiry
      )
    ) {
      Swal.fire({
        title: "Failed!",
        text: `Please provide name, number, cvv & expiry date correctly`,
        icon: "error",
        allowOutsideClick: false,
      })
      validated = false
      return
    } else if (!sameAddress) {
      if (
        !(
          data.billing_country &&
          data.billing_state &&
          data.billing_city &&
          data.billing_line1 &&
          data.billing_zipcode
        )
      ) {
        !data.billing_country &&
          setErrors({ ["billing_country"]: "Country should not be empty" })
        !data.billing_state &&
          setErrors({ ["billing_state"]: "State should not be empty" })
        !data.billing_city &&
          setErrors({ ["billing_city"]: "City should not be empty" })
        !data.billing_line1 &&
          setErrors({ ["billing_line1"]: "Address should not be empty" })
        !data.billing_zipcode &&
          setErrors({ ["billing_zipcode"]: "Zip code should not be empty" })
        validated = false
      }
    }
    const regex = new RegExp("(?:0[1-9]|1[0-2])([0-9]{2})", "g")

    if (!regex.test(cardDetails.expiry?.replace(/\//g, ""))) {
      Swal.fire({
        title: "Failed!",
        text: `Expiry date is invalid.`,
        icon: "error",
        allowOutsideClick: false,
      })
      validated = false
    }

    if (!recaptchaToken) {
      Swal.fire({
        title: "Failed!",
        text: "Please complete the reCAPTCHA validation",
        icon: "error",
        allowOutsideClick: false,
      })
      return
    }

    const selectedCountry = sameAddress ? data.country : data.billing_country
    const selectedCountryCode = countriesWithStates.filter(
      c => c.country === selectedCountry
    )[0]?.code

    const d = {
      billing_country: selectedCountryCode,
      billing_state: sameAddress ? data.state : data.billing_state,
      billing_city: sameAddress ? data.city : data.billing_city,
      billing_line1: sameAddress ? data.line1 : data.billing_line1,
      billing_line2: sameAddress ? data.line2 : data.billing_line2,
      billing_zipcode: sameAddress ? data.zipcode : data.billing_zipcode,
    }

    if (validated === true) {
      if (!sameAddress) {
        validateAddress(d, () => {
          storage.setUserInfo(storage.BILLING_KEY, {
            same_address: sameAddress,
            ...d,
          })

          onConfirm(currentStepCount + 1, {
            cvs: cardDetails.cvc,
            name: cardDetails.name,
            number: cardDetails.number,
            expiry: cardDetails.expiry,
          })
        })
      } else {
        storage.setUserInfo(storage.BILLING_KEY, {
          same_address: sameAddress,
          billing_country: selectedCountryCode,
          tax_type: data.tax_type,
          vat_no: data.vat_no,
          ...d,
        })

        onConfirm(currentStepCount + 1, {
          cvs: cardDetails.cvc,
          name: cardDetails.name,
          number: cardDetails.number,
          expiry: cardDetails.expiry,
        })
      }
    }
  }

  const validateAddress = (data, cb) => {
    fetch(`${API_URL}validate/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        street_address: `${data.billing_line1}~${data.billing_line2}`,
        city: data.billing_city,
        country_code: data.billing_country,
        country_area: data.billing_state,
        postal_code: data.billing_zipcode,
      }),
    })
      .then(res => res.json())
      .then(resData => {
        if (resData?.errors == null) {
          cb()
          return
        }
        const customErrors = destructJsonObj(resData.errors)
        console.log(customErrors)
        setCustomErrors(customErrors)
      })
      .catch(e => {
        console.log(e)
        if (e?.errors == null) return

        const customErrors = destructJsonObj(e.errors)
        console.log(customErrors)
        setCustomErrors(customErrors)
      })
  }

  const handleInputFocus = e => {
    const { name } = e.target
    setCardDetails({ ...cardDetails, focus: name })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    let newValue = value

    // Card number Validator
    if (name == "number" && value.length > 16) {
      newValue = newValue.slice(0, 16)
    }

    // CVV Validator
    if (name == "cvc" && value.length > 4) {
      newValue = newValue.slice(0, 4)
    }

    if (name == "expiry") newValue = value.replace(/\//g, "")

    // Expiry Validator
    if (name == "expiry" && value.length > 2) {
      const month = newValue.slice(0, 2)
      const year = newValue.slice(2, 4)

      newValue = `${month}/${year}`
    }

    setCardDetails({ ...cardDetails, [name]: newValue })
  }

  return (
    <div className="billing-inner-card">
      <p className="mb-2">Please enter a payment method for registration</p>

      <ul className="list-disc">
        <li>
          AlignerBase requires a credit card on file to create your free account
        </li>
        <li>
          This is only for validation, and you will not be charged at this time
        </li>
        <li>
          You will be able to add or delete payment methods from your account
          later{" "}
        </li>
        <li>Your payment information will be held securely through Stripe</li>
      </ul>

      <style>
        {`
          .img-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .img-wrapper img {
            width: 100% !important;
            height: auto !important; /* Maintain the aspect ratio */
          }
        `}
      </style>

      <div className="img-wrapper">
        <img src={image} />
      </div>

      <div className="pb-4" />

      <div id="PaymentForm">
        <Cards
          cvc={cardDetails.cvc}
          expiry={cardDetails.expiry}
          focused={cardDetails.focus}
          name={cardDetails.name}
          number={cardDetails.number}
        />
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Card holder name"
            type="text"
            name="name"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <p className={"error-text"}>{errors.name?.message}</p>

          <div className="pb-4" />

          <div className="flex mb-4" style={{ gap: "8px" }}>
            <div style={{ flex: 4 }}>
              <input
                placeholder="Card Number"
                type="number"
                name="number"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={cardDetails.number}
              />
              <p className={"error-text"}>{errors.number?.message}</p>
            </div>
            <div style={{ flex: 2 }}>
              <input
                placeholder="MM/YY"
                type="text"
                name="expiry"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={cardDetails.expiry}
              />
              <p className={"error-text"}>{errors.expiry?.message}</p>
            </div>
            <div style={{ flex: 2 }}>
              <input
                placeholder="CVV"
                type="number"
                name="cvc"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={cardDetails.cvc}
              />
              <p className={"error-text"}>{errors.cvv?.message}</p>
            </div>
          </div>
          <h6 >
            Sales Tax / VAT number (if available):
          </h6>
          <div style={{ display: "flex", gap: "8px" }}>
            <div style={{ flex: 1 }}>
              <select {...register("tax_type")} defaultValue="">
                <option key="0" value="">
                  {taxTypeLoading ? "Loading..." : "Select tax type..."}
                </option>
                {!taxTypeLoading &&
                  taxTypes.map((taxType, index) => {
                    return (
                      <option
                        key={taxType.key + "_" + index + 1}
                        value={taxType.value}
                      >
                        {taxType.key}
                      </option>
                    )
                  })}
              </select>
              <p className={"error-text"}>
                {formErrors.AorB &&
                  getValues().tax_type.length <= 0 &&
                  "Please select tax type against provided vat no."}
              </p>
            </div>
            <div style={{ flex: 1 }}>
              <input
                placeholder="VAT/Sales Tax Number"
                type={"text"}
                {...register("vat_no")}
              />
              <p className={"error-text"}>
                {formErrors.AorB &&
                  getValues().vat_no.length <= 0 &&
                  "Please enter vat no. against selected tax type"}
              </p>
            </div>
          </div>

          <div className="pb-4" />

          <h5>Billing Address</h5>

          <div className="mb-4 flex items-center gap-4">
            <input
              id="same-address-checkbox"
              type="checkbox"
              defaultChecked={
                (storage.getUserInfo(storage.BILLING_KEY) &&
                  storage.getUserInfo(storage.BILLING_KEY)?.["same_address"]) ??
                true
              }
              checked={sameAddress}
              onChange={e => setSameAddress(e.target.checked)}
            />
            <label htmlFor="same-address-checkbox" className="lowercase">
              Address is same as business address
            </label>
          </div>

          {!sameAddress ? (
            <>
              <div style={{ flex: 1 }}>
                <select
                  {...register("billing_country")}
                  defaultValue=""
                  onChange={e => populateState(e)}
                >
                  <option key="0" value="" disabled>
                    Select country...
                  </option>
                  {countriesWithStates.map((country, index) => {
                    return (
                      <option key={country.country} value={country.country}>
                        {country.country}
                      </option>
                    )
                  })}
                </select>
                <p className={"error-text"}>{errors?.billing_country}</p>
                <p className={"error-text"}>{customErrors?.["country_code"]}</p>
              </div>
              <div className="pb-4" />

              <div style={{ flex: 1 }}>
                <select {...register("billing_state")} defaultValue="">
                  <option key={0} value="" disabled>
                    Select state...
                  </option>
                  {states.map((state, index) => {
                    return (
                      <option key={index + 1} value={state}>
                        {state}
                      </option>
                    )
                  })}
                </select>
                <p className={"error-text"}>{errors?.billing_state}</p>
                <p className={"error-text"}>{customErrors?.["country_area"]}</p>
              </div>
              <div className="pb-4" />

              <input
                placeholder="Address Line 1"
                type={"text"}
                {...register("billing_line1")}
              />
              <p className={"error-text"}>{errors?.billing_line1}</p>
              <p className={"error-text"}>{customErrors?.["street_address"]}</p>

              <div className="pb-4" />

              <input
                placeholder="Address Line 2"
                type={"text"}
                {...register("billing_line2")}
              />
              <p className={"error-text"}>{errors?.billing_line2}</p>

              <div className="pb-4" />

              <div style={{ flex: 1 }}>
                <input
                  placeholder="City"
                  type={"text"}
                  {...register("billing_city")}
                />
                <p className={"error-text"}>{errors?.billing_city}</p>
                <p className={"error-text"}>{customErrors?.["city"]}</p>
              </div>
              <div className="pb-4" />

              <div style={{ flex: 1 }}>
                <input
                  placeholder="Postal Code"
                  type={"number"}
                  {...register("billing_zipcode")}
                />
                <p className={"error-text"}>{errors?.billing_zipcode}</p>
                <p className={"error-text"}>{customErrors?.["postal_code"]}</p>
              </div>
              <div className="pb-4" />
            </>
          ) : (
            <>
              <input type={"hidden"} {...register("country")} />
              <input type={"hidden"} {...register("state")} />
              <input type={"hidden"} {...register("city")} />
              <input type={"hidden"} {...register("line1")} />
              <input type={"hidden"} {...register("line2")} />
              <input type={"hidden"} {...register("zipcode")} />
            </>
          )}

          <div className="text-right mb-2">
            {!taxTypeLoading ? (
              <>
                <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={token => setRecaptchaToken(token)} onExpired={() => setRecaptchaToken(null)} />
                <br />
                <button onClick={() => onPrev(currentStepCount - 1)}>
                  Previous
                </button>
                <span className="px-1" />
                <button style={{ backgroundColor: "#001a1c" }} type="submit">&nbsp; Create &nbsp;</button>
              </>
            ) : (
              <>Loading...</>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default  React.memo(BillingInformation)
