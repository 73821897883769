export const destructJsonObj = obj => {
  let arr = {}
  recursiveFunction(obj, arr)
  return arr
}

const recursiveFunction = (obj, arr) => {
  for (let k in obj)
    obj[k] instanceof Object
      ? recursiveFunction(obj[k], arr)
      : (arr[k] = obj[k])
}

export const createDateFormat = date => {
  if (date === null || date === "") return null
  const dateObj = new Date(date)
  return (
    dateObj.getFullYear() +
    "-" +
    (dateObj.getMonth() + 1) +
    "-" +
    dateObj.getDate()
  )
}

export const padZero = (str, max = 1) => {
  str = str.toString()
  return str.length < max ? padZero("0" + str, max) : str
}

export const ucFirst = value => {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : ""
}
