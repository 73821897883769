import React from "react"
import "./index.scss"

const MultiStepForm = ({ heading, activeStep, steps }) => {
  return (
    <div className={"container"}>
      <div className="heading">{heading}</div>
      <div className={"inner-container"}>{steps[activeStep]}</div>
    </div>
  )
}

export default MultiStepForm
