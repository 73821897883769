import React, { useState, useEffect } from "react"
import { PORTAL_URL } from "../../../utils/Constants"
import storage from "../../../utils/local-storage"

const GetStartedComponent = ({ onConfirm, currentStepCount }) => {
  const [isChecked, setIsChecked] = useState()

  useEffect(() => {
    setIsChecked(storage.getUserInfo(storage.AGREE_TO_TNC_KEY) ?? false)
  }, [])

  return (
    <div>
      <h4 className="mb-2">Sign Up Form</h4>
      <p className="mb-4">
        Complete this short form to create your AlignerBase account and start
        submitting orders.
      </p>
      <div className="mb-4">
        <input
          id="terms-checkbox"
          type="checkbox"
          defaultChecked={isChecked}
          onChange={e => setIsChecked(e.target.checked)}
        />{" "}
        <label htmlFor="terms-checkbox">
          I have read and agree to the AlignerBase{" "}
          <a className={"text-sky-700"} href={"/terms-of-service"}>
            Terms and Conditions
          </a>
          ,{" "}
          <a className={"text-sky-700"} href={"/privacy-policy"}>
            Privacy Policy
          </a>
          , and{" "}
          <a className={"text-sky-700"} href={"/business-associate-agreement"}>
            Business Associate Agreement
          </a>
        </label>
      </div>

      <div className="text-right mb-2">
        <button
          disabled={!isChecked}
          onClick={() => {
            storage.setUserInfo(storage.AGREE_TO_TNC_KEY, 1)
            onConfirm(currentStepCount + 1)
          }}
        >
          Next
        </button>
      </div>

      <div className="text-center">
        Already have an account?{" "}
        <a className={"text-sky-700"} href={PORTAL_URL}>
          Login
        </a>
      </div>
    </div>
  )
}

export default React.memo(GetStartedComponent)
