export const countriesWithStates = [
  {
    country: "Albania",
    code: "AL",
    states: [
      "Berat",
      "Dibres",
      "Durres",
      "Elbasan",
      "Fier",
      "Gjirokastre",
      "Korce",
      "Kukes",
      "Lezhe",
      "Shkoder",
      "Tirane",
      "Vlore",
    ],
    phCode: "+355",
  },
  {
    country: "Algeria",
    code: "DZ",
    states: [
      "Adrar",
      "Ain Defla",
      "Ain Temouchent",
      "Alger",
      "Annaba",
      "Batna",
      "Bechar",
      "Bejaia",
      "Biskra",
      "Blida",
      "Bordj Bou Arreridj",
      "Bouira",
      "Boumerdes",
      "Chlef",
      "Constantine",
      "Djelfa",
      "El Bayadh",
      "El Oued",
      "El Tarf",
      "Ghardaia",
      "Guelma",
      "Illizi",
      "Jijel",
      "Khenchela",
      "Laghouat",
      "Muaskar",
      "Medea",
      "Mila",
      "Mostaganem",
      "M'Sila",
      "Naama",
      "Oran",
      "Ouargla",
      "Oum el Bouaghi",
      "Relizane",
      "Saida",
      "Setif",
      "Sidi Bel Abbes",
      "Skikda",
      "Souk Ahras",
      "Tamanghasset",
      "Tebessa",
      "Tiaret",
      "Tindouf",
      "Tipaza",
      "Tissemsilt",
      "Tizi Ouzou",
      "Tlemcen",
    ],
    phCode: "+213",
  },
  {
    country: "Andorra",
    code: "AD",
    states: [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Escaldes-Engordany",
      "La Massana",
      "Ordino",
      "Sant Julià de Lòria",
    ],
    phCode: "+376",
  },
  {
    country: "Angola",
    code: "AO",
    states: [
      "Bengo",
      "Benguela",
      "Bie",
      "Cabinda",
      "Cuando Cubango",
      "Cuanza Norte",
      "Cuanza Sul",
      "Cunene",
      "Huambo",
      "Huila",
      "Luanda",
      "Lunda Norte",
      "Lunda Sul",
      "Malanje",
      "Moxico",
      "Namibe",
      "Uige",
      "Zaire",
    ],
    phCode: "+244",
  },
  {
    country: "Antigua and Barbuda",
    code: "AG",
    states: [
      "Barbuda",
      "Redonda",
      "Saint George",
      "Saint John",
      "Saint Mary",
      "Saint Paul",
      "Saint Peter",
      "Saint Philip",
    ],
    phCode: "+1268",
  },
  {
    country: "Argentina",
    code: "AR",
    states: [
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Ciudad Autónoma de Buenos Aires",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucumán",
    ],
    phCode: "+54",
  },
  {
    country: "Armenia",
    code: "AM",
    states: [
      "Aragatsotn",
      "Ararat",
      "Armavir",
      "Geghark'unik'",
      "Kotayk'",
      "Lorri",
      "Shirak",
      "Syunik'",
      "Tavush",
      "Vayots' Dzor",
      "Yerevan",
    ],
    phCode: "+374",
  },
  {
    country: "Australia",
    code: "AU",
    states: [
      "Australian Capital Territory",
      "Jervis Bay Territory",
      "New South Wales",
      "Northern Territory",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia",
    ],
    phCode: "+61",
  },
  {
    country: "Austria",
    code: "AT",
    states: [
      "Burgenland",
      "Kaernten",
      "Niederoesterreich",
      "Oberoesterreich",
      "Salzburg",
      "Steiermark",
      "Tirol",
      "Vorarlberg",
      "Wien",
    ],
    phCode: "+43",
  },
  {
    country: "Azerbaijan",
    code: "AZ",
    states: [
      "Abseron Rayonu",
      "Agcabadi Rayonu",
      "Agdam Rayonu",
      "Agdas Rayonu",
      "Agstafa Rayonu",
      "Agsu Rayonu",
      "Astara Rayonu",
      "Balakan Rayonu",
      "Barda Rayonu",
      "Beylaqan Rayonu",
      "Bilasuvar Rayonu",
      "Cabrayil Rayonu",
      "Calilabad Rayonu",
      "Daskasan Rayonu",
      "Davaci Rayonu",
      "Fuzuli Rayonu",
      "Gadabay Rayonu",
      "Goranboy Rayonu",
      "Goycay Rayonu",
      "Haciqabul Rayonu",
      "Imisli Rayonu",
      "Ismayilli Rayonu",
      "Kalbacar Rayonu",
      "Kurdamir Rayonu",
      "Lacin Rayonu",
      "Lankaran Rayonu",
      "Lerik Rayonu",
      "Masalli Rayonu",
      "Neftcala Rayonu",
      "Oguz Rayonu",
      "Qabala Rayonu",
      "Qax Rayonu",
      "Qazax Rayonu",
      "Qobustan Rayonu",
      "Quba Rayonu",
      "Qubadli Rayonu",
      "Qusar Rayonu",
      "Saatli Rayonu",
      "Sabirabad Rayonu",
      "Saki Rayonu",
      "Salyan Rayonu",
      "Samaxi Rayonu",
      "Samkir Rayonu",
      "Samux Rayonu",
      "Siyazan Rayonu",
      "Susa Rayonu",
      "Tartar Rayonu",
      "Tovuz Rayonu",
      "Ucar Rayonu",
      "Xacmaz Rayonu",
      "Xanlar Rayonu",
      "Xizi Rayonu",
      "Xocali Rayonu",
      "Xocavand Rayonu",
      "Yardimli Rayonu",
      "Yevlax Rayonu",
      "Zangilan Rayonu",
      "Zaqatala Rayonu",
      "Zardab Rayonu",
      "Ali Bayramli Sahari",
      "Baki Sahari",
      "Ganca Sahari",
      "Lankaran Sahari",
      "Mingacevir Sahari",
      "Naftalan Sahari",
      "Saki Sahari",
      "Sumqayit Sahari",
      "Susa Sahari",
      "Xankandi Sahari",
      "Yevlax Sahari",
      "Naxcivan Muxtar",
    ],
    phCode: "+994",
  },
  {
    country: "Bahamas",
    code: "BS",
    states: [
      "Abaco Islands",
      "Acklins",
      "Andros Island",
      "Berry Islands",
      "Bimini",
      "Cat Island",
      "Crooked Island",
      "Eleuthera",
      "Exuma and Cays",
      "Grand Bahama",
      "Harbour Island",
      "Inagua",
      "Long Island",
      "Mayaguana",
      "New Providence",
      "Ragged Island",
      "Rum Cay",
      "San Salvador",
      "Spanish Wells",
    ],
    phCode: "+1242",
  },
  {
    country: "Bahrain",
    code: "BH",
    states: [
      "Al Hadd",
      "Al Manamah",
      "Al Mintaqah al Gharbiyah",
      "Al Mintaqah al Wusta",
      "Al Mintaqah ash Shamaliyah",
      "Al Muharraq",
      "Ar Rifa' wa al Mintaqah al Janubiyah",
      "Jidd Hafs",
      "Madinat Hamad",
      "Madinat 'Isa",
      "Juzur Hawar",
      "Sitrah",
    ],
    phCode: "+973",
  },
  {
    country: "Bangladesh",
    code: "BD",
    states: ["Barisal", "Chittagong", "Dhaka", "Khulna", "Rajshahi", "Sylhet"],
    phCode: "+880",
  },
  {
    country: "Barbados",
    code: "BB",
    states: [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas",
    ],
    phCode: "+1246",
  },
  {
    country: "Belarus",
    code: "BY",
    states: [
      "Brest",
      "Homyel",
      "Horad Minsk",
      "Hrodna",
      "Mahilyow",
      "Minsk",
      "Vitsyebsk",
    ],
    phCode: "+375",
  },
  {
    country: "Belgium",
    code: "BE",
    states: [
      "Antwerpen",
      "Brabant Wallon",
      "Brussels",
      "Flanders",
      "Hainaut",
      "Liege",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Oost-Vlaanderen",
      "Vlaams-Brabant",
      "Wallonia",
      "West-Vlaanderen",
    ],
    phCode: "+32",
  },
  {
    country: "Belize",
    code: "BZ",
    states: [
      "Belize",
      "Cayo",
      "Corozal",
      "Orange Walk",
      "Stann Creek",
      "Toledo",
    ],
    phCode: "+501",
  },
  {
    country: "Benin",
    code: "BJ",
    states: [
      "Alibori",
      "Atakora",
      "Atlantique",
      "Borgou",
      "Collines",
      "Donga",
      "Kouffo",
      "Littoral",
      "Mono",
      "Oueme",
      "Plateau",
      "Zou",
    ],
    phCode: "+229",
  },
  {
    country: "Bermuda",
    code: "BM",
    states: [
      "Devonshire",
      "Hamilton",
      "Hamilton",
      "Paget",
      "Pembroke",
      "Saint George",
      "Saint George's",
      "Sandys",
      "Smith's",
      "Southampton",
      "Warwick",
    ],
    phCode: "+1441",
  },
  {
    country: "Bhutan",
    code: "BT",
    states: [
      "Bumthang",
      "Chukha",
      "Dagana",
      "Gasa",
      "Haa",
      "Lhuntse",
      "Mongar",
      "Paro",
      "Pemagatshel",
      "Punakha",
      "Samdrup Jongkhar",
      "Samtse",
      "Sarpang",
      "Thimphu",
      "Trashigang",
      "Trashiyangste",
      "Trongsa",
      "Tsirang",
      "Wangdue Phodrang",
      "Zhemgang",
    ],
    phCode: "+975",
  },
  {
    country: "Bolivia",
    code: "BO",
    states: [
      "Chuquisaca",
      "Cochabamba",
      "Beni",
      "La Paz",
      "Oruro",
      "Pando",
      "Potosi",
      "Santa Cruz",
      "Tarija",
    ],
    phCode: "+591",
  },
  {
    country: "Botswana",
    code: "BW",
    states: [
      "Central",
      "Ghanzi",
      "Kgalagadi",
      "Kgatleng",
      "Kweneng",
      "North East",
      "North West",
      "South East",
      "Southern",
    ],
    phCode: "+267",
  },
  {
    country: "Brazil",
    code: "BR",
    states: [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahia",
      "Ceará",
      "Distrito Federal",
      "Espírito Santo",
      "Goiás",
      "Maranhão",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins",
    ],
    phCode: "+55",
  },
  {
    country: "Bulgaria",
    code: "BG",
    states: [
      "Blagoevgrad",
      "Burgas",
      "Dobrich",
      "Gabrovo",
      "Khaskovo",
      "Kurdzhali",
      "Kyustendil",
      "Lovech",
      "Montana",
      "Pazardzhik",
      "Pernik",
      "Pleven",
      "Plovdiv",
      "Razgrad",
      "Ruse",
      "Shumen",
      "Silistra",
      "Sliven",
      "Smolyan",
      "Sofiya",
      "Sofiya-Grad",
      "Stara Zagora",
      "Turgovishte",
      "Varna",
      "Veliko Turnovo",
      "Vidin",
      "Vratsa",
      "Yambol",
    ],
    phCode: "+359",
  },
  {
    country: "Burkina Faso",
    code: "BF",
    states: [
      "Bale",
      "Bam",
      "Banwa",
      "Bazega",
      "Bougouriba",
      "Boulgou",
      "Boulkiemde",
      "Comoe",
      "Ganzourgou",
      "Gnagna",
      "Gourma",
      "Houet",
      "Ioba",
      "Kadiogo",
      "Kenedougou",
      "Komondjari",
      "Kompienga",
      "Kossi",
      "Koulpelogo",
      "Kouritenga",
      "Kourweogo",
      "Leraba",
      "Loroum",
      "Mouhoun",
      "Namentenga",
      "Nahouri",
      "Nayala",
      "Noumbiel",
      "Oubritenga",
      "Oudalan",
      "Passore",
      "Poni",
      "Sanguie",
      "Sanmatenga",
      "Seno",
      "Sissili",
      "Soum",
      "Sourou",
      "Tapoa",
      "Tuy",
      "Yagha",
      "Yatenga",
      "Ziro",
      "Zondoma",
      "Zoundweogo",
    ],
    phCode: "+226",
  },
  {
    country: "Burundi",
    code: "BI",
    states: [
      "Bubanza",
      "Bujumbura Mairie",
      "Bujumbura Rural",
      "Bururi",
      "Cankuzo",
      "Cibitoke",
      "Gitega",
      "Karuzi",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muramvya",
      "Muyinga",
      "Mwaro",
      "Ngozi",
      "Rutana",
      "Ruyigi",
    ],
    phCode: "+257",
  },
  {
    country: "Cambodia",
    code: "KH",
    states: [
      "Banteay Mean Chey",
      "Batdambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Spoe",
      "Kampong Thum",
      "Kampot",
      "Kandal",
      "Koh Kong",
      "Kracheh",
      "Mondol Kiri",
      "Otdar Mean Chey",
      "Pouthisat",
      "Preah Vihear",
      "Prey Veng",
      "Rotanakir",
      "Siem Reab",
      "Stoeng Treng",
      "Svay Rieng",
      "Takao",
      "Keb",
      "Pailin",
      "Phnom Penh",
      "Preah Seihanu",
    ],
    phCode: "+855",
  },
  {
    country: "Cameroon",
    code: "CM",
    states: [
      "Adamaoua",
      "Centre",
      "Est",
      "Extreme-Nord",
      "Littoral",
      "Nord",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Ouest",
    ],
    phCode: "+237",
  },
  {
    country: "Canada",
    code: "CA",
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
    phCode: "+1",
  },
  {
    country: "Central African Republic",
    code: "CF",
    states: [
      "Bamingui-Bangoran",
      "Bangui",
      "Basse-Kotto",
      "Haute-Kotto",
      "Haut-Mbomou",
      "Kemo",
      "Lobaye",
      "Mambere-Kadei",
      "Mbomou",
      "Nana-Grebizi",
      "Nana-Mambere",
      "Ombella-Mpoko",
      "Ouaka",
      "Ouham",
      "Ouham-Pende",
      "Sangha-Mbaere",
      "Vakaga",
    ],
    phCode: "+236",
  },
  {
    country: "Chad",
    code: "TD",
    states: [
      "Batha",
      "Biltine",
      "Borkou-Ennedi-Tibesti",
      "Chari-Baguirmi",
      "Guéra",
      "Kanem",
      "Lac",
      "Logone Occidental",
      "Logone Oriental",
      "Mayo-Kebbi",
      "Moyen-Chari",
      "Ouaddaï",
      "Salamat",
      "Tandjile",
    ],
    phCode: "+235",
  },
  {
    country: "Chile",
    code: "CL",
    states: [
      "Antofagasta",
      "Araucanía",
      "Arica y Parinacota",
      "Atacama",
      "Aysén del General Carlos Ibáñez del Campo",
      "Biobío",
      "Coquimbo",
      "Libertador General Bernardo O'Higgins",
      "Los Lagos",
      "Los Ríos",
      "Magallanes y de la Antártica Chilena",
      "Maule",
      "Metropolitana de Santiago",
      "Ñuble",
      "Tarapacá",
      "Valparaíso",
    ],
    phCode: "+56",
  },
  {
    country: "China",
    code: "CN",
    states: [
      "安徽省",
      "澳门",
      "北京市",
      "重庆市",
      "福建省",
      "甘肃省",
      "广东省",
      "广西",
      "贵州省",
      "海南省",
      "河北省",
      "河南省",
      "黑龙江省",
      "湖北省",
      "湖南省",
      "吉林省",
      "江苏省",
      "江西省",
      "辽宁省",
      "内蒙古",
      "宁夏",
      "青海省",
      "山东省",
      "山西省",
      "陕西省",
      "上海市",
      "四川省",
      "台湾",
      "天津市",
      "西藏",
      "香港",
      "新疆",
      "云南省",
      "浙江省",
    ],
    phCode: "+86",
  },
  {
    country: "Comoros",
    code: "KM",
    states: ["Grande Comore (Njazidja)", "Anjouan (Nzwani)", "Moheli (Mwali)"],
    phCode: "+269",
  },
  {
    country: "Costa Rica",
    code: "CR",
    states: [
      "Alajuela",
      "Cartago",
      "Guanacaste",
      "Heredia",
      "Limon",
      "Puntarenas",
      "San Jose",
    ],
    phCode: "+506",
  },
  {
    country: "Croatia",
    code: "HR",
    states: [
      "Bjelovarsko-Bilogorska",
      "Brodsko-Posavska",
      "Dubrovacko-Neretvanska",
      "Istarska",
      "Karlovacka",
      "Koprivnicko-Krizevacka",
      "Krapinsko-Zagorska",
      "Licko-Senjska",
      "Medimurska",
      "Osjecko-Baranjska",
      "Pozesko-Slavonska",
      "Primorsko-Goranska",
      "Sibensko-Kninska",
      "Sisacko-Moslavacka",
      "Splitsko-Dalmatinska",
      "Varazdinska",
      "Viroviticko-Podravska",
      "Vukovarsko-Srijemska",
      "Zadarska",
      "Zagreb",
      "Zagrebacka",
    ],
    phCode: "+385",
  },
  {
    country: "Cyprus",
    code: "CY",
    states: [
      "Famagusta",
      "Kyrenia",
      "Larnaca",
      "Limassol",
      "Nicosia",
      "Paphos",
    ],
    phCode: "+357",
  },
  {
    country: "Czech Republic",
    code: "CZ",
    states: [
      "Jihocesky Kraj",
      "Jihomoravsky Kraj",
      "Karlovarsky Kraj",
      "Kralovehradecky Kraj",
      "Liberecky Kraj",
      "Moravskoslezsky Kraj",
      "Olomoucky Kraj",
      "Pardubicky Kraj",
      "Plzensky Kraj",
      "Praha",
      "Stredocesky Kraj",
      "Ustecky Kraj",
      "Vysocina",
      "Zlinsky Kraj",
    ],
    phCode: "+420",
  },
  {
    country: "Denmark",
    code: "DK",
    states: [
      "Arhus",
      "Bornholm",
      "Frederiksberg",
      "Frederiksborg",
      "Fyn",
      "Kobenhavn",
      "Kobenhavns",
      "Nordjylland",
      "Ribe",
      "Ringkobing",
      "Roskilde",
      "Sonderjylland",
      "Storstrom",
      "Vejle",
      "Vestsjalland",
      "Viborg",
    ],
    phCode: "+45",
  },
  {
    country: "Djibouti",
    code: "DJ",
    states: ["Ali Sabih", "Dikhil", "Djibouti", "Obock", "Tadjoura"],
    phCode: "+253",
  },
  {
    country: "Dominica",
    code: "DM",
    states: [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Joseph",
      "Saint Luke",
      "Saint Mark",
      "Saint Patrick",
      "Saint Paul",
      "Saint Peter",
    ],
    phCode: "+1767",
  },
  {
    country: "Dominican Republic",
    code: "DO",
    states: [
      "Azua",
      "Baoruco",
      "Barahona",
      "Dajabon",
      "Distrito Nacional",
      "Duarte",
      "Elias Pina",
      "El Seibo",
      "Espaillat",
      "Hato Mayor",
      "Independencia",
      "La Altagracia",
      "La Romana",
      "La Vega",
      "Maria Trinidad Sanchez",
      "Monsenor Nouel",
      "Monte Cristi",
      "Monte Plata",
      "Pedernales",
      "Peravia",
      "Puerto Plata",
      "Salcedo",
      "Samana",
      "Sanchez Ramirez",
      "San Cristobal",
      "San Jose de Ocoa",
      "San Juan",
      "San Pedro de Macoris",
      "Santiago",
      "Santiago Rodriguez",
      "Santo Domingo",
      "Valverde",
    ],
    phCode: "+1849",
  },
  {
    country: "East Timor",
    code: "TL",
    states: [
      "Aileu",
      "Ainaro",
      "Baucau",
      "Bobonaro",
      "Cova-Lima",
      "Dili",
      "Ermera",
      "Lautem",
      "Liquica",
      "Manatuto",
      "Manufahi",
      "Oecussi",
      "Viqueque",
    ],
    phCode: "+670",
  },
  {
    country: "Ecuador",
    code: "EC",
    states: [
      "Azuay",
      "Bolivar",
      "Canar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galapagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los Rios",
      "Manabi",
      "Morona-Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Sucumbios",
      "Tungurahua",
      "Zamora-Chinchipe",
    ],
    phCode: "+593",
  },
  {
    country: "Egypt",
    code: "EG",
    states: [
      "Ad Daqahliyah",
      "Al Bahr al Ahmar",
      "Al Buhayrah",
      "Al Fayyum",
      "Al Gharbiyah",
      "Al Iskandariyah",
      "Al Isma'iliyah",
      "Al Jizah",
      "Al Minufiyah",
      "Al Minya",
      "Al Qahirah",
      "Al Qalyubiyah",
      "Al Wadi al Jadid",
      "Ash Sharqiyah",
      "As Suways",
      "Aswan",
      "Asyut",
      "Bani Suwayf",
      "Bur Sa'id",
      "Dumyat",
      "Janub Sina'",
      "Kafr ash Shaykh",
      "Matruh",
      "Qina",
      "Shamal Sina'",
      "Suhaj",
    ],
    phCode: "+20",
  },
  {
    country: "El Salvador",
    code: "SV",
    states: [
      "Ahuachapán",
      "Cabañas",
      "Chalatenango",
      "Cuscatlán",
      "La Libertad",
      "La Paz",
      "La Unión",
      "Morazán",
      "San Miguel",
      "San Salvador",
      "San Vicente",
      "Santa Ana",
      "Sonsonate",
      "Usulután",
    ],
    phCode: "+503",
  },
  {
    country: "Equatorial Guinea",
    code: "GQ",
    states: [
      "Annobon",
      "Bioko Norte",
      "Bioko Sur",
      "Centro Sur",
      "Kie-Ntem",
      "Litoral",
      "Wele-Nzas",
    ],
    phCode: "+240",
  },
  {
    country: "Eritrea",
    code: "ER",
    states: [
      "Anseba",
      "Debub",
      "Debubawi K'eyih Bahri",
      "Gash Barka",
      "Ma'akel",
      "Semenawi Keyih Bahri",
    ],
    phCode: "+291",
  },
  {
    country: "Estonia",
    code: "EE",
    states: [
      "Harjumaa (Tallinn)",
      "Hiiumaa (Kardla)",
      "Ida-Virumaa (Johvi)",
      "Jarvamaa (Paide)",
      "Jogevamaa (Jogeva)",
      "Laanemaa (Haapsalu)",
      "Laane-Virumaa (Rakvere)",
      "Parnumaa (Parnu)",
      "Polvamaa (Polva)",
      "Raplamaa (Rapla)",
      "Saaremaa (Kuressaare)",
      "Tartumaa (Tartu)",
      "Valgamaa (Valga)",
      "Viljandimaa (Viljandi)",
      "Vorumaa (Voru)",
    ],
    phCode: "+372",
  },
  {
    country: "Ethiopia",
    code: "ET",
    states: [
      "Addis Ababa",
      "Afar",
      "Amhara",
      "Binshangul Gumuz",
      "Dire Dawa",
      "Gambela Hizboch",
      "Harari",
      "Oromia",
      "Somali",
      "Tigray",
      "Southern Nations, Nationalities, and Peoples Region",
    ],
    phCode: "+251",
  },
  {
    country: "Fiji",
    code: "FJ",
    states: [
      "Central (Suva)",
      "Eastern (Levuka)",
      "Northern (Labasa)",
      "Rotuma",
      "Western (Lautoka)",
    ],
    phCode: "+679",
  },
  {
    country: "Finland",
    code: "FI",
    states: [
      "Aland",
      "Etela-Suomen Laani",
      "Ita-Suomen Laani",
      "Lansi-Suomen Laani",
      "Lappi",
      "Oulun Laani",
    ],
    phCode: "+358",
  },
  {
    country: "France",
    code: "FR",
    states: [
      "Alsace",
      "Aquitaine",
      "Auvergne",
      "Basse-Normandie",
      "Bourgogne",
      "Bretagne",
      "Centre",
      "Champagne-Ardenne",
      "Corse",
      "Franche-Comte",
      "Haute-Normandie",
      "Ile-de-France",
      "Languedoc-Roussillon",
      "Limousin",
      "Lorraine",
      "Midi-Pyrenees",
      "Nord-Pas-de-Calais",
      "Pays de la Loire",
      "Picardie",
      "Poitou-Charentes",
      "Provence-Alpes-Cote d'Azur",
      "Rhone-Alpes",
    ],
    phCode: "+33",
  },
  {
    country: "Gabon",
    code: "GA",
    states: [
      "Estuaire",
      "Haut-Ogooue",
      "Moyen-Ogooue",
      "Ngounie",
      "Nyanga",
      "Ogooue-Ivindo",
      "Ogooue-Lolo",
      "Ogooue-Maritime",
      "Woleu-Ntem",
    ],
    phCode: "+241",
  },
  {
    country: "Gambia",
    code: "GM",
    states: [
      "Banjul",
      "Central River",
      "Lower River",
      "North Bank",
      "Upper River",
      "Western",
    ],
    phCode: "+220",
  },
  {
    country: "Georgia",
    code: "GE",
    states: [
      "Abkhazia",
      "Adjara",
      "Guria",
      "Imereti",
      "Kakheti",
      "Kvemo Kartli",
      "Mtskheta-Mtianeti",
      "Racha-Lechkhumi and Kvemo Svaneti",
      "Samegrelo-Zemo Svaneti",
      "Samtskhe-Javakheti",
      "Shida Kartli",
      "Tbilisi",
    ],
    phCode: "+995",
  },
  {
    country: "Germany",
    code: "DE",
    states: [
      "Baden-Wuerttemberg",
      "Bayern",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hessen",
      "Mecklenburg-Vorpommern",
      "Niedersachsen",
      "Nordrhein-Westfalen",
      "Rheinland-Pfalz",
      "Saarland",
      "Sachsen",
      "Sachsen-Anhalt",
      "Schleswig-Holstein",
      "Thueringen",
    ],
    phCode: "+49",
  },
  {
    country: "Ghana",
    code: "GH",
    states: [
      "Ashanti",
      "Brong-Ahafo",
      "Central",
      "Eastern",
      "Greater Accra",
      "Northern",
      "Upper East",
      "Upper West",
      "Volta",
      "Western",
    ],
    phCode: "+233",
  },
  {
    country: "Greece",
    code: "GR",
    states: [
      "Agion Oros",
      "Achaia",
      "Aitolia kai Akarmania",
      "Argolis",
      "Arkadia",
      "Arta",
      "Attiki",
      "Chalkidiki",
      "Chanion",
      "Chios",
      "Dodekanisos",
      "Drama",
      "Evros",
      "Evrytania",
      "Evvoia",
      "Florina",
      "Fokidos",
      "Fthiotis",
      "Grevena",
      "Ileia",
      "Imathia",
      "Ioannina",
      "Irakleion",
      "Karditsa",
      "Kastoria",
      "Kavala",
      "Kefallinia",
      "Kerkyra",
      "Kilkis",
      "Korinthia",
      "Kozani",
      "Kyklades",
      "Lakonia",
      "Larisa",
      "Lasithi",
      "Lefkas",
      "Lesvos",
      "Magnisia",
      "Messinia",
      "Pella",
      "Pieria",
      "Preveza",
      "Rethynnis",
      "Rodopi",
      "Samos",
      "Serrai",
      "Thesprotia",
      "Thessaloniki",
      "Trikala",
      "Voiotia",
      "Xanthi",
      "Zakynthos",
    ],
    phCode: "+30",
  },
  {
    country: "Greenland",
    code: "GL",
    states: [
      "Avannaa (Nordgronland)",
      "Tunu (Ostgronland)",
      "Kitaa (Vestgronland)",
    ],
    phCode: "+299",
  },
  {
    country: "Grenada",
    code: "GD",
    states: [
      "Carriacou and Petit Martinique",
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Mark",
      "Saint Patrick",
    ],
    phCode: "+1473",
  },
  {
    country: "Guatemala",
    code: "GT",
    states: [
      "Alta Verapaz",
      "Baja Verapaz",
      "Chimaltenango",
      "Chiquimula",
      "El Progreso",
      "Escuintla",
      "Guatemala",
      "Huehuetenango",
      "Izabal",
      "Jalapa",
      "Jutiapa",
      "Peten",
      "Quetzaltenango",
      "Quiche",
      "Retalhuleu",
      "Sacatepequez",
      "San Marcos",
      "Santa Rosa",
      "Solola",
      "Suchitepequez",
      "Totonicapan",
      "Zacapa",
    ],
    phCode: "+502",
  },
  {
    country: "Guinea",
    code: "GN",
    states: [
      "Beyla",
      "Boffa",
      "Boke",
      "Conakry",
      "Coyah",
      "Dabola",
      "Dalaba",
      "Dinguiraye",
      "Dubreka",
      "Faranah",
      "Forecariah",
      "Fria",
      "Gaoual",
      "Gueckedou",
      "Kankan",
      "Kerouane",
      "Kindia",
      "Kissidougou",
      "Koubia",
      "Koundara",
      "Kouroussa",
      "Labe",
      "Lelouma",
      "Lola",
      "Macenta",
      "Mali",
      "Mamou",
      "Mandiana",
      "Nzerekore",
      "Pita",
      "Siguiri",
      "Telimele",
      "Tougue",
      "Yomou",
    ],
    phCode: "+224",
  },
  {
    country: "Guyana",
    code: "GY",
    states: [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo",
    ],
    phCode: "+595",
  },
  {
    country: "Haiti",
    code: "HT",
    states: [
      "Artibonite",
      "Centre",
      "Grand 'Anse",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Est",
    ],
    phCode: "+509",
  },
  {
    country: "Honduras",
    code: "HN",
    states: [
      "Atlantida",
      "Choluteca",
      "Colon",
      "Comayagua",
      "Copan",
      "Cortes",
      "El Paraiso",
      "Francisco Morazan",
      "Gracias a Dios",
      "Intibuca",
      "Islas de la Bahia",
      "La Paz",
      "Lempira",
      "Ocotepeque",
      "Olancho",
      "Santa Barbara",
      "Valle",
      "Yoro",
    ],
    phCode: "+504",
  },
  {
    country: "Hong Kong",
    code: "HK",
    states: ["九龍", "香港島", "新界"],
    phCode: "+852",
  },
  {
    country: "Hungary",
    code: "HU",
    states: [
      "Bacs-Kiskun",
      "Baranya",
      "Bekes",
      "Borsod-Abauj-Zemplen",
      "Csongrad",
      "Fejer",
      "Gyor-Moson-Sopron",
      "Hajdu-Bihar",
      "Heves",
      "Jasz-Nagykun-Szolnok",
      "Komarom-Esztergom",
      "Nograd",
      "Pest",
      "Somogy",
      "Szabolcs-Szatmar-Bereg",
      "Tolna",
      "Vas",
      "Veszprem",
      "Zala",
      "Bekescsaba",
      "Debrecen",
      "Dunaujvaros",
      "Eger",
      "Gyor",
      "Hodmezovasarhely",
      "Kaposvar",
      "Kecskemet",
      "Miskolc",
      "Nagykanizsa",
      "Nyiregyhaza",
      "Pecs",
      "Sopron",
      "Szeged",
      "Szekesfehervar",
      "Szolnok",
      "Szombathely",
      "Tatabanya",
      "Veszprem",
      "Zalaegerszeg",
    ],
    phCode: "+36",
  },
  {
    country: "Iceland",
    code: "IS",
    states: [
      "Austurland",
      "Hofudhborgarsvaedhi",
      "Nordhurland Eystra",
      "Nordhurland Vestra",
      "Sudhurland",
      "Sudhurnes",
      "Vestfirdhir",
      "Vesturland",
    ],
    phCode: "+354",
  },
  {
    country: "India",
    code: "IN",
    states: [
      "Andaman & Nicobar",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra & Nagar Haveli & Daman & Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu & Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
    phCode: "+91",
  },
  {
    country: "Indonesia",
    code: "ID",
    states: [
      "Aceh",
      "Bali",
      "Banten",
      "Bengkulu",
      "Gorontalo",
      "Irian Jaya Barat",
      "Jakarta Raya",
      "Jambi",
      "Jawa Barat",
      "Jawa Tengah",
      "Jawa Timur",
      "Kalimantan Barat",
      "Kalimantan Selatan",
      "Kalimantan Tengah",
      "Kalimantan Timur",
      "Kepulauan Bangka Belitung",
      "Kepulauan Riau",
      "Lampung",
      "Maluku",
      "Maluku Utara",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Papua",
      "Riau",
      "Sulawesi Barat",
      "Sulawesi Selatan",
      "Sulawesi Tengah",
      "Sulawesi Tenggara",
      "Sulawesi Utara",
      "Sumatera Barat",
      "Sumatera Selatan",
      "Sumatera Utara",
      "Yogyakarta",
    ],
    phCode: "+62",
  },
  {
    country: "Ireland",
    code: "IE",
    states: [
      "Carlow",
      "Cavan",
      "Clare",
      "Cork",
      "Donegal",
      "Dublin",
      "Galway",
      "Kerry",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Leitrim",
      "Limerick",
      "Longford",
      "Louth",
      "Mayo",
      "Meath",
      "Monaghan",
      "Offaly",
      "Roscommon",
      "Sligo",
      "Tipperary",
      "Waterford",
      "Westmeath",
      "Wexford",
      "Wicklow",
    ],
    phCode: "+353",
  },
  {
    country: "Israel",
    code: "IL",
    states: [
      "Central",
      "Haifa",
      "Jerusalem",
      "Northern",
      "Southern",
      "Tel Aviv",
    ],
    phCode: "+972",
  },
  {
    country: "Italy",
    code: "IT",
    states: [
      "Agrigento",
      "Alessandria",
      "Ancona",
      "Aosta",
      "Arezzo",
      "Ascoli Piceno",
      "Asti",
      "Avellino",
      "Bari",
      "Barletta-Andria-Trani",
      "Belluno",
      "Benevento",
      "Bergamo",
      "Biella",
      "Bologna",
      "Bolzano",
      "Brescia",
      "Brindisi",
      "Cagliari",
      "Caltanissetta",
      "Campobasso",
      "Caserta",
      "Catania",
      "Catanzaro",
      "Chieti",
      "Como",
      "Cosenza",
      "Cremona",
      "Crotone",
      "Cuneo",
      "Enna",
      "Fermo",
      "Ferrara",
      "Firenze",
      "Foggia",
      "Forlì-Cesena",
      "Frosinone",
      "Genova",
      "Gorizia",
      "Grosseto",
      "Imperia",
      "Isernia",
      "L'Aquila",
      "La Spezia",
      "Latina",
      "Lecce",
      "Lecco",
      "Livorno",
      "Lodi",
      "Lucca",
      "Macerata",
      "Mantova",
      "Massa-Carrara",
      "Matera",
      "Messina",
      "Milano",
      "Modena",
      "Monza e Brianza",
      "Napoli",
      "Novara",
      "Nuoro",
      "Oristano",
      "Padova",
      "Palermo",
      "Parma",
      "Pavia",
      "Perugia",
      "Pesaro e Urbino",
      "Pescara",
      "Piacenza",
      "Pisa",
      "Pistoia",
      "Pordenone",
      "Potenza",
      "Prato",
      "Ragusa",
      "Ravenna",
      "Reggio Calabria",
      "Reggio Emilia",
      "Rieti",
      "Rimini",
      "Roma",
      "Rovigo",
      "Salerno",
      "Sassari",
      "Savona",
      "Siena",
      "Siracusa",
      "Sondrio",
      "Sud Sardegna",
      "Taranto",
      "Teramo",
      "Terni",
      "Torino",
      "Trapani",
      "Trento",
      "Treviso",
      "Trieste",
      "Udine",
      "Varese",
      "Venezia",
      "Verbano-Cusio-Ossola",
      "Vercelli",
      "Verona",
      "Vibo Valentia",
      "Vicenza",
      "Viterbo",
    ],
    phCode: "+39",
  },
  {
    country: "Jamaica",
    code: "JM",
    states: [
      "Clarendon",
      "Hanover",
      "Kingston",
      "Manchester",
      "Portland",
      "Saint Andrew",
      "Saint Ann",
      "Saint Catherine",
      "Saint Elizabeth",
      "Saint James",
      "Saint Mary",
      "Saint Thomas",
      "Trelawny",
      "Westmoreland",
    ],
    phCode: "+1876",
  },
  {
    country: "Japan",
    code: "JP",
    states: [
      "Aichi",
      "Akita",
      "Aomori",
      "Chiba",
      "Ehime",
      "Fukui",
      "Fukuoka",
      "Fukushima",
      "Gifu",
      "Gumma",
      "Hiroshima",
      "Hokkaido",
      "Hyogo",
      "Ibaraki",
      "Ishikawa",
      "Iwate",
      "Kagawa",
      "Kagoshima",
      "Kanagawa",
      "Kochi",
      "Kumamoto",
      "Kyoto",
      "Mie",
      "Miyagi",
      "Miyazaki",
      "Nagano",
      "Nagasaki",
      "Nara",
      "Niigata",
      "Oita",
      "Okayama",
      "Okinawa",
      "Osaka",
      "Saga",
      "Saitama",
      "Shiga",
      "Shimane",
      "Shizuoka",
      "Tochigi",
      "Tokushima",
      "Tokyo",
      "Tottori",
      "Toyama",
      "Wakayama",
      "Yamagata",
      "Yamaguchi",
      "Yamanashi",
    ],
    phCode: "+81",
  },
  {
    country: "Jordan",
    code: "JO",
    states: [
      "Ajlun",
      "Al 'Aqabah",
      "Al Balqa'",
      "Al Karak",
      "Al Mafraq",
      "'Amman",
      "At Tafilah",
      "Az Zarqa'",
      "Irbid",
      "Jarash",
      "Ma'an",
      "Madaba",
    ],
    phCode: "+962",
  },
  {
    country: "Kazakhstan",
    code: "KZ",
    states: [
      "Almaty Oblysy",
      "Almaty Qalasy",
      "Aqmola Oblysy",
      "Aqtobe Oblysy",
      "Astana Qalasy",
      "Atyrau Oblysy",
      "Batys Qazaqstan Oblysy",
      "Bayqongyr Qalasy",
      "Mangghystau Oblysy",
      "Ongtustik Qazaqstan Oblysy",
      "Pavlodar Oblysy",
      "Qaraghandy Oblysy",
      "Qostanay Oblysy",
      "Qyzylorda Oblysy",
      "Shyghys Qazaqstan Oblysy",
      "Soltustik Qazaqstan Oblysy",
      "Zhambyl Oblysy",
    ],
    phCode: "+77",
  },
  {
    country: "Kenya",
    code: "KE",
    states: [
      "Central",
      "Coast",
      "Eastern",
      "Nairobi Area",
      "North Eastern",
      "Nyanza",
      "Rift Valley",
      "Western",
    ],
    phCode: "+254",
  },
  {
    country: "Kuwait",
    code: "KW",
    states: [
      "Al Ahmadi",
      "Al Farwaniyah",
      "Al Asimah",
      "Al Jahra",
      "Hawalli",
      "Mubarak Al-Kabeer",
    ],
    phCode: "+965",
  },
  {
    country: "Laos",
    code: "LA",
    states: [
      "Attapu",
      "Bokeo",
      "Bolikhamxai",
      "Champasak",
      "Houaphan",
      "Khammouan",
      "Louangnamtha",
      "Louangphrabang",
      "Oudomxai",
      "Phongsali",
      "Salavan",
      "Savannakhet",
      "Viangchan",
      "Viangchan",
      "Xaignabouli",
      "Xaisomboun",
      "Xekong",
      "Xiangkhoang",
    ],
    phCode: "+856",
  },
  {
    country: "Latvia",
    code: "LV",
    states: [
      "Aizkraukles Rajons",
      "Aluksnes Rajons",
      "Balvu Rajons",
      "Bauskas Rajons",
      "Cesu Rajons",
      "Daugavpils",
      "Daugavpils Rajons",
      "Dobeles Rajons",
      "Gulbenes Rajons",
      "Jekabpils Rajons",
      "Jelgava",
      "Jelgavas Rajons",
      "Jurmala",
      "Kraslavas Rajons",
      "Kuldigas Rajons",
      "Liepaja",
      "Liepajas Rajons",
      "Limbazu Rajons",
      "Ludzas Rajons",
      "Madonas Rajons",
      "Ogres Rajons",
      "Preilu Rajons",
      "Rezekne",
      "Rezeknes Rajons",
      "Riga",
      "Rigas Rajons",
      "Saldus Rajons",
      "Talsu Rajons",
      "Tukuma Rajons",
      "Valkas Rajons",
      "Valmieras Rajons",
      "Ventspils",
      "Ventspils Rajons",
    ],
    phCode: "+371",
  },
  {
    country: "Lesotho",
    code: "LS",
    states: [
      "Berea",
      "Butha-Buthe",
      "Leribe",
      "Mafeteng",
      "Maseru",
      "Mohale's Hoek",
      "Mokhotlong",
      "Qacha's Nek",
      "Quthing",
      "Thaba-Tseka",
    ],
    phCode: "+266",
  },
  {
    country: "Liberia",
    code: "LR",
    states: [
      "Bomi",
      "Bong",
      "Gbarpolu",
      "Grand Bassa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Grand Kru",
      "Lofa",
      "Margibi",
      "Maryland",
      "Montserrado",
      "Nimba",
      "River Cess",
      "River Gee",
      "Sinoe",
    ],
    phCode: "+231",
  },
  {
    country: "Liechtenstein",
    code: "LI",
    states: [
      "Balzers",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz",
    ],
    phCode: "+423",
  },
  {
    country: "Lithuania",
    code: "LT",
    states: [
      "Alytaus",
      "Kauno",
      "Klaipedos",
      "Marijampoles",
      "Panevezio",
      "Siauliu",
      "Taurages",
      "Telsiu",
      "Utenos",
      "Vilniaus",
    ],
    phCode: "+370",
  },
  {
    country: "Luxembourg",
    code: "LU",
    states: ["Diekirch", "Grevenmacher", "Luxembourg"],
    phCode: "+352",
  },
  {
    country: "Macedonia",
    code: "MK",
    states: [
      "Aerodrom",
      "Aracinovo",
      "Berovo",
      "Bitola",
      "Bogdanci",
      "Bogovinje",
      "Bosilovo",
      "Brvenica",
      "Butel",
      "Cair",
      "Caska",
      "Centar",
      "Centar Zupa",
      "Cesinovo",
      "Cucer-Sandevo",
      "Debar",
      "Debartsa",
      "Delcevo",
      "Demir Hisar",
      "Demir Kapija",
      "Dojran",
      "Dolneni",
      "Drugovo",
      "Gazi Baba",
      "Gevgelija",
      "Gjorce Petrov",
      "Gostivar",
      "Gradsko",
      "Ilinden",
      "Jegunovce",
      "Karbinci",
      "Karpos",
      "Kavadarci",
      "Kicevo",
      "Kisela Voda",
      "Kocani",
      "Konce",
      "Kratovo",
      "Kriva Palanka",
      "Krivogastani",
      "Krusevo",
      "Kumanovo",
      "Lipkovo",
      "Lozovo",
      "Makedonska Kamenica",
      "Makedonski Brod",
      "Mavrovo i Rastusa",
      "Mogila",
      "Negotino",
      "Novaci",
      "Novo Selo",
      "Ohrid",
      "Oslomej",
      "Pehcevo",
      "Petrovec",
      "Plasnica",
      "Prilep",
      "Probistip",
      "Radovis",
      "Rankovce",
      "Resen",
      "Rosoman",
      "Saraj",
      "Skopje",
      "Sopiste",
      "Staro Nagoricane",
      "Stip",
      "Struga",
      "Strumica",
      "Studenicani",
      "Suto Orizari",
      "Sveti Nikole",
      "Tearce",
      "Tetovo",
      "Valandovo",
      "Vasilevo",
      "Veles",
      "Vevcani",
      "Vinica",
      "Vranestica",
      "Vrapciste",
      "Zajas",
      "Zelenikovo",
      "Zelino",
      "Zrnovci",
    ],
    phCode: "+389",
  },
  {
    country: "Madagascar",
    code: "MG",
    states: [
      "Antananarivo",
      "Antsiranana",
      "Fianarantsoa",
      "Mahajanga",
      "Toamasina",
      "Toliara",
    ],
    phCode: "+261",
  },
  {
    country: "Malawi",
    code: "MW",
    states: [
      "Balaka",
      "Blantyre",
      "Chikwawa",
      "Chiradzulu",
      "Chitipa",
      "Dedza",
      "Dowa",
      "Karonga",
      "Kasungu",
      "Likoma",
      "Lilongwe",
      "Machinga",
      "Mangochi",
      "Mchinji",
      "Mulanje",
      "Mwanza",
      "Mzimba",
      "Ntcheu",
      "Nkhata Bay",
      "Nkhotakota",
      "Nsanje",
      "Ntchisi",
      "Phalombe",
      "Rumphi",
      "Salima",
      "Thyolo",
      "Zomba",
    ],
    phCode: "+265",
  },
  {
    country: "Malaysia",
    code: "MY",
    states: [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Malacca",
      "Negeri Sembilan",
      "Pahang",
      "Perak",
      "Perlis",
      "Penang",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu",
    ],
    phCode: "+60",
  },
  {
    country: "Maldives",
    code: "MV",
    states: [
      "Alifu",
      "Baa",
      "Dhaalu",
      "Faafu",
      "Gaafu Alifu",
      "Gaafu Dhaalu",
      "Gnaviyani",
      "Haa Alifu",
      "Haa Dhaalu",
      "Kaafu",
      "Laamu",
      "Lhaviyani",
      "Maale",
      "Meemu",
      "Noonu",
      "Raa",
      "Seenu",
      "Shaviyani",
      "Thaa",
      "Vaavu",
    ],
    phCode: "+960",
  },
  {
    country: "Mali",
    code: "ML",
    states: [
      "Bamako (Capital)",
      "Gao",
      "Kayes",
      "Kidal",
      "Koulikoro",
      "Mopti",
      "Segou",
      "Sikasso",
      "Tombouctou",
    ],
    phCode: "+223",
  },
  {
    country: "Mauritania",
    code: "MR",
    states: [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dakhlet Nouadhibou",
      "Gorgol",
      "Guidimaka",
      "Hodh Ech Chargui",
      "Hodh El Gharbi",
      "Inchiri",
      "Nouakchott",
      "Tagant",
      "Tiris Zemmour",
      "Trarza",
    ],
    phCode: "+222",
  },
  {
    country: "Mauritius",
    code: "MU",
    states: [
      "Agalega Islands",
      "Black River",
      "Cargados Carajos Shoals",
      "Flacq",
      "Grand Port",
      "Moka",
      "Pamplemousses",
      "Plaines Wilhems",
      "Port Louis",
      "Riviere du Rempart",
      "Rodrigues",
      "Savanne",
    ],
    phCode: "",
  },
  {
    country: "Mexico",
    code: "MX",
    states: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Ciudad de México",
      "Coahuila de Zaragoza",
      "Colima",
      "Durango",
      "Estado de México",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Michoacán",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatán",
      "Zacatecas",
    ],
    phCode: "+52",
  },
  {
    country: "Moldova",
    code: "MD",
    states: [
      "Anenii Noi",
      "Basarabeasca",
      "Briceni",
      "Cahul",
      "Cantemir",
      "Calarasi",
      "Causeni",
      "Cimislia",
      "Criuleni",
      "Donduseni",
      "Drochia",
      "Dubasari",
      "Edinet",
      "Falesti",
      "Floresti",
      "Glodeni",
      "Hincesti",
      "Ialoveni",
      "Leova",
      "Nisporeni",
      "Ocnita",
      "Orhei",
      "Rezina",
      "Riscani",
      "Singerei",
      "Soldanesti",
      "Soroca",
      "Stefan-Voda",
      "Straseni",
      "Taraclia",
      "Telenesti",
      "Ungheni",
      "Balti",
      "Bender",
      "Chisinau",
      "Gagauzia",
      "Stinga Nistrului",
    ],
    phCode: "+373",
  },
  {
    country: "Mongolia",
    code: "MN",
    states: [
      "Arhangay",
      "Bayanhongor",
      "Bayan-Olgiy",
      "Bulgan",
      "Darhan Uul",
      "Dornod",
      "Dornogovi",
      "Dundgovi",
      "Dzavhan",
      "Govi-Altay",
      "Govi-Sumber",
      "Hentiy",
      "Hovd",
      "Hovsgol",
      "Omnogovi",
      "Orhon",
      "Ovorhangay",
      "Selenge",
      "Suhbaatar",
      "Tov",
      "Ulaanbaatar",
      "Uvs",
    ],
    phCode: "+976",
  },
  {
    country: "Morocco",
    code: "MA",
    states: [
      "Agadir",
      "Al Hoceima",
      "Azilal",
      "Beni Mellal",
      "Ben Slimane",
      "Boulemane",
      "Casablanca",
      "Chaouen",
      "El Jadida",
      "El Kelaa des Sraghna",
      "Er Rachidia",
      "Essaouira",
      "Fes",
      "Figuig",
      "Guelmim",
      "Ifrane",
      "Kenitra",
      "Khemisset",
      "Khenifra",
      "Khouribga",
      "Laayoune",
      "Larache",
      "Marrakech",
      "Meknes",
      "Nador",
      "Ouarzazate",
      "Oujda",
      "Rabat-Sale",
      "Safi",
      "Settat",
      "Sidi Kacem",
      "Tangier",
      "Tan-Tan",
      "Taounate",
      "Taroudannt",
      "Tata",
      "Taza",
      "Tetouan",
      "Tiznit",
    ],
    phCode: "+212",
  },
  {
    country: "Mozambique",
    code: "MZ",
    states: [
      "Cabo Delgado",
      "Gaza",
      "Inhambane",
      "Manica",
      "Maputo",
      "Cidade de Maputo",
      "Nampula",
      "Niassa",
      "Sofala",
      "Tete",
      "Zambezia",
    ],
    phCode: "+258",
  },
  {
    country: "Namibia",
    code: "NA",
    states: [
      "Caprivi",
      "Erongo",
      "Hardap",
      "Karas",
      "Khomas",
      "Kunene",
      "Ohangwena",
      "Okavango",
      "Omaheke",
      "Omusati",
      "Oshana",
      "Oshikoto",
      "Otjozondjupa",
    ],
    phCode: "+264",
  },
  {
    country: "Nepal",
    code: "NP",
    states: [
      "Bagmati",
      "Bheri",
      "Dhawalagiri",
      "Gandaki",
      "Janakpur",
      "Karnali",
      "Kosi",
      "Lumbini",
      "Mahakali",
      "Mechi",
      "Narayani",
      "Rapti",
      "Sagarmatha",
      "Seti",
    ],
    phCode: "+977",
  },
  {
    country: "Netherlands",
    code: "NL",
    states: [
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "Noord-Brabant",
      "Noord-Holland",
      "Overijssel",
      "Utrecht",
      "Zeeland",
      "Zuid-Holland",
    ],
    phCode: "+31",
  },
  {
    country: "New Zealand",
    code: "NZ",
    states: [
      "Auckland",
      "Bay of Plenty",
      "Canterbury",
      "Chatham Islands",
      "Gisborne",
      "Hawke's Bay",
      "Manawatu-Wanganui",
      "Marlborough",
      "Nelson",
      "Northland",
      "Otago",
      "Southland",
      "Taranaki",
      "Tasman",
      "Waikato",
      "Wellington",
      "West Coast",
    ],
    phCode: "+64",
  },
  {
    country: "Nicaragua",
    code: "NI",
    states: [
      "Atlantico Norte",
      "Atlantico Sur",
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Esteli",
      "Granada",
      "Jinotega",
      "Leon",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "Nueva Segovia",
      "Rio San Juan",
      "Rivas",
    ],
    phCode: "+505",
  },
  {
    country: "Niger",
    code: "NE",
    states: [
      "Agadez",
      "Diffa",
      "Dosso",
      "Maradi",
      "Niamey",
      "Tahoua",
      "Tillaberi",
      "Zinder",
    ],
    phCode: "+227",
  },
  {
    country: "Nigeria",
    code: "NG",
    states: [
      "Abia",
      "Abuja Federal Capital",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nassarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
    ],
    phCode: "+234",
  },
  {
    country: "Norway",
    code: "NO",
    states: [
      "Akershus",
      "Aust-Agder",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "More og Romsdal",
      "Nordland",
      "Nord-Trondelag",
      "Oppland",
      "Oslo",
      "Ostfold",
      "Rogaland",
      "Sogn og Fjordane",
      "Sor-Trondelag",
      "Telemark",
      "Troms",
      "Vest-Agder",
      "Vestfold",
    ],
    phCode: "+47",
  },
  {
    country: "Oman",
    code: "OM",
    states: [
      "Ad Dakhiliyah",
      "Al Batinah",
      "Al Wusta",
      "Ash Sharqiyah",
      "Az Zahirah",
      "Masqat",
      "Musandam",
      "Dhofar",
    ],
    phCode: "+968",
  },
  {
    country: "Pakistan",
    code: "PK",
    states: [
      "Balochistan",
      "North-West Frontier Province",
      "Punjab",
      "Sindh",
      "Islamabad Capital Territory",
      "Federally Administered Tribal Areas",
    ],
    phCode: "+92",
  },
  {
    country: "Panama",
    code: "PA",
    states: [
      "Bocas del Toro",
      "Chiriqui",
      "Cocle",
      "Colon",
      "Darien",
      "Herrera",
      "Los Santos",
      "Panama",
      "San Blas",
      "Veraguas",
    ],
    phCode: "+507",
  },
  {
    country: "Papua New Guinea",
    code: "PG",
    states: [
      "Bougainville",
      "Central",
      "Chimbu",
      "Eastern Highlands",
      "East New Britain",
      "East Sepik",
      "Enga",
      "Gulf",
      "Madang",
      "Manus",
      "Milne Bay",
      "Morobe",
      "National Capital",
      "New Ireland",
      "Northern",
      "Sandaun",
      "Southern Highlands",
      "Western",
      "Western Highlands",
      "West New Britain",
    ],
    phCode: "+675",
  },
  {
    country: "Paraguay",
    code: "PY",
    states: [
      "Alto Paraguay",
      "Alto Parana",
      "Amambay",
      "Asuncion",
      "Boqueron",
      "Caaguazu",
      "Caazapa",
      "Canindeyu",
      "Central",
      "Concepcion",
      "Cordillera",
      "Guaira",
      "Itapua",
      "Misiones",
      "Neembucu",
      "Paraguari",
      "Presidente Hayes",
      "San Pedro",
    ],
    phCode: "+595",
  },
  {
    country: "Peru",
    code: "PE",
    states: [
      "Amazonas",
      "Ancash",
      "Apurimac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Junin",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martin",
      "Tacna",
      "Tumbes",
      "Ucayali",
    ],
    phCode: "+51",
  },
  {
    country: "Philippines",
    code: "PH",
    states: [
      "Abra",
      "Agusan del Norte",
      "Agusan del Sur",
      "Aklan",
      "Albay",
      "Antique",
      "Apayao",
      "Aurora",
      "Basilan",
      "Bataan",
      "Batanes",
      "Batangas",
      "Biliran",
      "Benguet",
      "Bohol",
      "Bukidnon",
      "Bulacan",
      "Cagayan",
      "Camarines Norte",
      "Camarines Sur",
      "Camiguin",
      "Capiz",
      "Catanduanes",
      "Cavite",
      "Cebu",
      "Compostela",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Oriental",
      "Eastern Samar",
      "Guimaras",
      "Ifugao",
      "Ilocos Norte",
      "Ilocos Sur",
      "Iloilo",
      "Isabela",
      "Kalinga",
      "Laguna",
      "Lanao del Norte",
      "Lanao del Sur",
      "La Union",
      "Leyte",
      "Maguindanao",
      "Marinduque",
      "Masbate",
      "Mindoro Occidental",
      "Mindoro Oriental",
      "Misamis Occidental",
      "Misamis Oriental",
      "Mountain Province",
      "Negros Occidental",
      "Negros Oriental",
      "North Cotabato",
      "Northern Samar",
      "Nueva Ecija",
      "Nueva Vizcaya",
      "Palawan",
      "Pampanga",
      "Pangasinan",
      "Quezon",
      "Quirino",
      "Rizal",
      "Romblon",
      "Samar",
      "Sarangani",
      "Siquijor",
      "Sorsogon",
      "South Cotabato",
      "Southern Leyte",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tarlac",
      "Tawi-Tawi",
      "Zambales",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Sibugay",
    ],
    phCode: "+63",
  },
  {
    country: "Poland",
    code: "PL",
    states: [
      "Greater Poland (Wielkopolskie)",
      "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
      "Lesser Poland (Malopolskie)",
      "Lodz (Lodzkie)",
      "Lower Silesian (Dolnoslaskie)",
      "Lublin (Lubelskie)",
      "Lubusz (Lubuskie)",
      "Masovian (Mazowieckie)",
      "Opole (Opolskie)",
      "Podlasie (Podlaskie)",
      "Pomeranian (Pomorskie)",
      "Silesian (Slaskie)",
      "Subcarpathian (Podkarpackie)",
      "Swietokrzyskie (Swietokrzyskie)",
      "Warmian-Masurian (Warminsko-Mazurskie)",
      "West Pomeranian (Zachodniopomorskie)",
    ],
    phCode: "+48",
  },
  {
    country: "Portugal",
    code: "PT",
    states: [
      "Aveiro",
      "Acores",
      "Beja",
      "Braga",
      "Braganca",
      "Castelo Branco",
      "Coimbra",
      "Evora",
      "Faro",
      "Guarda",
      "Leiria",
      "Lisboa",
      "Madeira",
      "Portalegre",
      "Porto",
      "Santarem",
      "Setubal",
      "Viana do Castelo",
      "Vila Real",
      "Viseu",
    ],
    phCode: "+351",
  },
  {
    country: "Qatar",
    code: "QA",
    states: [
      "Ad Dawhah",
      "Al Ghuwayriyah",
      "Al Jumayliyah",
      "Al Khawr",
      "Al Wakrah",
      "Ar Rayyan",
      "Jarayan al Batinah",
      "Madinat ash Shamal",
      "Umm Sa'id",
      "Umm Salal",
    ],
    phCode: "+974",
  },
  {
    country: "Romania",
    code: "RO",
    states: [
      "Alba",
      "Arad",
      "Arges",
      "Bacau",
      "Bihor",
      "Bistrita-Nasaud",
      "Botosani",
      "Braila",
      "Brasov",
      "Bucuresti",
      "Buzau",
      "Calarasi",
      "Caras-Severin",
      "Cluj",
      "Constanta",
      "Covasna",
      "Dimbovita",
      "Dolj",
      "Galati",
      "Gorj",
      "Giurgiu",
      "Harghita",
      "Hunedoara",
      "Ialomita",
      "Iasi",
      "Ilfov",
      "Maramures",
      "Mehedinti",
      "Mures",
      "Neamt",
      "Olt",
      "Prahova",
      "Salaj",
      "Satu Mare",
      "Sibiu",
      "Suceava",
      "Teleorman",
      "Timis",
      "Tulcea",
      "Vaslui",
      "Vilcea",
      "Vrancea",
    ],
    phCode: "+40",
  },
  {
    country: "Russia",
    code: "RU",
    states: [
      "Алтайский край",
      "Амурская область",
      "Архангельская область",
      "Астраханская область",
      "Белгородская область",
      "Брянская область",
      "Владимирская область",
      "Волгоградская область",
      "Вологодская область",
      "Воронежская область",
      "Еврейская автономная область",
      "Забайкальский край",
      "Ивановская область",
      "Иркутская область",
      "Кабардино-Балкарская Республика",
      "Калининградская область",
      "Калужская область",
      "Камчатский край",
      "Карачаево-Черкесская Республика",
      "Кемеровская область",
      "Кировская область",
      "Костромская область",
      "Краснодарский край",
      "Красноярский край",
      "Курганская область",
      "Курская область",
      "Ленинградская область",
      "Липецкая область",
      "Магаданская область",
      "Москва",
      "Московская область",
      "Мурманская область",
      "Ненецкий автономный округ",
      "Нижегородская область",
      "Новгородская область",
      "Новосибирская область",
      "Омская область",
      "Оренбургская область",
      "Орловская область",
      "Пензенская область",
      "Пермский край",
      "Приморский край",
      "Псковская область",
      "Республика Адыгея",
      "Республика Алтай",
      "Республика Башкортостан",
      "Республика Бурятия",
      "Республика Дагестан",
      "Республика Ингушетия",
      "Республика Калмыкия",
      "Республика Карелия",
      "Республика Коми",
      "Республика Крым",
      "Республика Марий Эл",
      "Республика Мордовия",
      "Республика Саха (Якутия)",
      "Республика Северная Осетия-Алания",
      "Республика Татарстан",
      "Республика Тыва",
      "Республика Удмуртия",
      "Республика Хакасия",
      "Ростовская область",
      "Рязанская область",
      "Самарская область",
      "Санкт-Петербург",
      "Саратовская область",
      "Сахалинская область",
      "Свердловская область",
      "Севастополь",
      "Смоленская область",
      "Ставропольский край",
      "Тамбовская область",
      "Тверская область",
      "Томская область",
      "Тульская область",
      "Тюменская область",
      "Ульяновская область",
      "Хабаровский край",
      "Ханты-Мансийский автономный округ",
      "Челябинская область",
      "Чеченская Республика",
      "Чувашская Республика",
      "Чукотский автономный округ",
      "Ямало-Ненецкий автономный округ",
      "Ярославская область",
    ],
    phCode: "+7",
  },
  {
    country: "Rwanda",
    code: "RW",
    states: [
      "Butare",
      "Byumba",
      "Cyangugu",
      "Gikongoro",
      "Gisenyi",
      "Gitarama",
      "Kibungo",
      "Kibuye",
      "Kigali Rurale",
      "Kigali-ville",
      "Umutara",
      "Ruhengeri",
    ],
    phCode: "+250",
  },
  {
    country: "Samoa",
    code: "WS",
    states: [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gagaifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano",
    ],
    phCode: "+685",
  },
  {
    country: "San Marino",
    code: "SM",
    states: [
      "Acquaviva",
      "Borgo Maggiore",
      "Chiesanuova",
      "Domagnano",
      "Faetano",
      "Fiorentino",
      "Montegiardino",
      "San Marino Citta",
      "Serravalle",
    ],
    phCode: "+378",
  },
  {
    country: "Saudi Arabia",
    code: "SA",
    states: [
      "Al Bahah",
      "Al Hudud ash Shamaliyah",
      "Al Jawf",
      "Al Madinah",
      "Al Qasim",
      "Ar Riyad",
      "Ash Sharqiyah",
      "'Asir",
      "Ha'il",
      "Jizan",
      "Makkah",
      "Najran",
      "Tabuk",
    ],
    phCode: "+966",
  },
  {
    country: "Senegal",
    code: "SN",
    states: [
      "Dakar",
      "Diourbel",
      "Fatick",
      "Kaolack",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "Tambacounda",
      "Thies",
      "Ziguinchor",
    ],
    phCode: "+221",
  },
  {
    country: "Serbia",
    code: "RS",
    states: [
      "Valjevo",
      "Šabac",
      "Čačak",
      "Jagodina",
      "Kruševac",
      "Kraljevo",
      "Kragujevac",
      "Užice",
      "Bor",
      "Požarevac",
      "Leskovac",
      "Niš",
      "Vranje",
      "Pirot",
      "Smederevo",
      "Prokuplje",
      "Zaječar",
      "Zrenjanin",
      "Subotica",
      "Kikinda",
      "Novi Sad",
      "Pančevo",
      "Sremska Mitrovica",
      "Sombor",
    ],
    phCode: "+381",
  },
  {
    country: "Seychelles",
    code: "SC",
    states: [
      "Anse aux Pins",
      "Anse Boileau",
      "Anse Etoile",
      "Anse Louis",
      "Anse Royale",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand' Anse",
      "Grand' Anse",
      "La Digue",
      "La Riviere Anglaise",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Saint Louis",
      "Takamaka",
    ],
    phCode: "+248",
  },
  {
    country: "Singapore",
    code: "SG",
    states: [
      "Aljunied Group Representation Constituency",
      "Ang Mo Kio Group Representation Constituency",
      "Bishan-Toa Payoh Group Representation Constituency",
      "Chua Chu Kang Group Representation Constituency",
      "East Coast Group Representation Constituency",
      "Holland-Bukit Timah Group Representation Constituency",
      "Jalan Besar Group Representation Constituency",
      "Jurong Group Representation Constituency",
      "Marine Parade Group Representation Constituency",
      "Marsiling-Yew Tee Group Representation Constituency",
      "Nee Soon Group Representation Constituency",
      "Pasir Ris-Punggol Group Representation Constituency",
      "Sengkang Group Representation Constituency",
      "Tampines Group Representation Constituency",
      "Tanjong Pagar Group Representation Constituency",
      "West Coast Group Representation Constituency",
    ],
    phCode: "+65",
  },
  {
    country: "Slovenia",
    code: "SI",
    states: [
      "Ajdovscina",
      "Beltinci",
      "Benedikt",
      "Bistrica ob Sotli",
      "Bled",
      "Bloke",
      "Bohinj",
      "Borovnica",
      "Bovec",
      "Braslovce",
      "Brda",
      "Brezice",
      "Brezovica",
      "Cankova",
      "Celje",
      "Cerklje na Gorenjskem",
      "Cerknica",
      "Cerkno",
      "Cerkvenjak",
      "Crensovci",
      "Crna na Koroskem",
      "Crnomelj",
      "Destrnik",
      "Divaca",
      "Dobje",
      "Dobrepolje",
      "Dobrna",
      "Dobrova-Horjul-Polhov Gradec",
      "Dobrovnik-Dobronak",
      "Dolenjske Toplice",
      "Dol pri Ljubljani",
      "Domzale",
      "Dornava",
      "Dravograd",
      "Duplek",
      "Gorenja Vas-Poljane",
      "Gorisnica",
      "Gornja Radgona",
      "Gornji Grad",
      "Gornji Petrovci",
      "Grad",
      "Grosuplje",
      "Hajdina",
      "Hoce-Slivnica",
      "Hodos-Hodos",
      "Horjul",
      "Hrastnik",
      "Hrpelje-Kozina",
      "Idrija",
      "Ig",
      "Ilirska Bistrica",
      "Ivancna Gorica",
      "Izola-Isola",
      "Jesenice",
      "Jezersko",
      "Jursinci",
      "Kamnik",
      "Kanal",
      "Kidricevo",
      "Kobarid",
      "Kobilje",
      "Kocevje",
      "Komen",
      "Komenda",
      "Koper-Capodistria",
      "Kostel",
      "Kozje",
      "Kranj",
      "Kranjska Gora",
      "Krizevci",
      "Krsko",
      "Kungota",
      "Kuzma",
      "Lasko",
      "Lenart",
      "Lendava-Lendva",
      "Litija",
      "Ljubljana",
      "Ljubno",
      "Ljutomer",
      "Logatec",
      "Loska Dolina",
      "Loski Potok",
      "Lovrenc na Pohorju",
      "Luce",
      "Lukovica",
      "Majsperk",
      "Maribor",
      "Markovci",
      "Medvode",
      "Menges",
      "Metlika",
      "Mezica",
      "Miklavz na Dravskem Polju",
      "Miren-Kostanjevica",
      "Mirna Pec",
      "Mislinja",
      "Moravce",
      "Moravske Toplice",
      "Mozirje",
      "Murska Sobota",
      "Muta",
      "Naklo",
      "Nazarje",
      "Nova Gorica",
      "Novo Mesto",
      "Odranci",
      "Oplotnica",
      "Ormoz",
      "Osilnica",
      "Pesnica",
      "Piran-Pirano",
      "Pivka",
      "Podcetrtek",
      "Podlehnik",
      "Podvelka",
      "Polzela",
      "Postojna",
      "Prebold",
      "Preddvor",
      "Prevalje",
      "Ptuj",
      "Puconci",
      "Race-Fram",
      "Radece",
      "Radenci",
      "Radlje ob Dravi",
      "Radovljica",
      "Ravne na Koroskem",
      "Razkrizje",
      "Ribnica",
      "Ribnica na Pohorju",
      "Rogasovci",
      "Rogaska Slatina",
      "Rogatec",
      "Ruse",
      "Salovci",
      "Selnica ob Dravi",
      "Semic",
      "Sempeter-Vrtojba",
      "Sencur",
      "Sentilj",
      "Sentjernej",
      "Sentjur pri Celju",
      "Sevnica",
      "Sezana",
      "Skocjan",
      "Skofja Loka",
      "Skofljica",
      "Slovenj Gradec",
      "Slovenska Bistrica",
      "Slovenske Konjice",
      "Smarje pri Jelsah",
      "Smartno ob Paki",
      "Smartno pri Litiji",
      "Sodrazica",
      "Solcava",
      "Sostanj",
      "Starse",
      "Store",
      "Sveta Ana",
      "Sveti Andraz v Slovenskih Goricah",
      "Sveti Jurij",
      "Tabor",
      "Tisina",
      "Tolmin",
      "Trbovlje",
      "Trebnje",
      "Trnovska Vas",
      "Trzic",
      "Trzin",
      "Turnisce",
      "Velenje",
      "Velika Polana",
      "Velike Lasce",
      "Verzej",
      "Videm",
      "Vipava",
      "Vitanje",
      "Vodice",
      "Vojnik",
      "Vransko",
      "Vrhnika",
      "Vuzenica",
      "Zagorje ob Savi",
      "Zalec",
      "Zavrc",
      "Zelezniki",
      "Zetale",
      "Ziri",
      "Zirovnica",
      "Zuzemberk",
      "Zrece",
    ],
    phCode: "+386",
  },
  {
    country: "Solomon Islands",
    code: "SB",
    states: [
      "Central",
      "Choiseul",
      "Guadalcanal",
      "Honiara",
      "Isabel",
      "Makira",
      "Malaita",
      "Rennell and Bellona",
      "Temotu",
      "Western",
    ],
    phCode: "+677",
  },
  {
    country: "Somalia",
    code: "SO",
    states: [
      "Awdal",
      "Bakool",
      "Banaadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiiraan",
      "Jubbada Dhexe",
      "Jubbada Hoose",
      "Mudug",
      "Nugaal",
      "Sanaag",
      "Shabeellaha Dhexe",
      "Shabeellaha Hoose",
      "Sool",
      "Togdheer",
      "Woqooyi Galbeed",
    ],
    phCode: "+252",
  },
  {
    country: "South Africa",
    code: "ZA",
    states: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "North-West",
      "Northern Cape",
      "Western Cape",
    ],
    phCode: "+27",
  },
  {
    country: "Spain",
    code: "ES",
    states: [
      "Álava",
      "Albacete",
      "Alicante",
      "Almería",
      "Asturias",
      "Ávila",
      "Badajoz",
      "Barcelona",
      "Burgos",
      "Cáceres",
      "Cádiz",
      "Cantabria",
      "Castellón",
      "Ceuta",
      "Ciudad Real",
      "Córdoba",
      "Cuenca",
      "Girona",
      "Granada",
      "Guadalajara",
      "Guipúzcoa",
      "Huelva",
      "Huesca",
      "Islas Baleares",
      "Jaén",
      "La Coruña",
      "La Rioja",
      "Las Palmas",
      "León",
      "Lérida",
      "Lugo",
      "Madrid",
      "Málaga",
      "Melilla",
      "Murcia",
      "Navarra",
      "Ourense",
      "Palencia",
      "Pontevedra",
      "Salamanca",
      "Santa Cruz de Tenerife",
      "Segovia",
      "Sevilla",
      "Soria",
      "Tarragona",
      "Teruel",
      "Toledo",
      "Valencia",
      "Valladolid",
      "Vizcaya",
      "Zamora",
      "Zaragoza",
    ],
    phCode: "+34",
  },
  {
    country: "Sri Lanka",
    code: "LK",
    states: [
      "Central",
      "North Central",
      "North Eastern",
      "North Western",
      "Sabaragamuwa",
      "Southern",
      "Uva",
      "Western",
    ],
    phCode: "+94",
  },
  {
    country: "Suriname",
    code: "SR",
    states: [
      "Brokopondo",
      "Commewijne",
      "Coronie",
      "Marowijne",
      "Nickerie",
      "Para",
      "Paramaribo",
      "Saramacca",
      "Sipaliwini",
      "Wanica",
    ],
    phCode: "+597",
  },
  {
    country: "Swaziland",
    code: "SZ",
    states: ["Hhohho", "Lubombo", "Manzini", "Shiselweni"],
    phCode: "+268",
  },
  {
    country: "Sweden",
    code: "SE",
    states: [
      "Blekinge",
      "Dalarnas",
      "Gavleborgs",
      "Gotlands",
      "Hallands",
      "Jamtlands",
      "Jonkopings",
      "Kalmar",
      "Kronobergs",
      "Norrbottens",
      "Orebro",
      "Ostergotlands",
      "Skane",
      "Sodermanlands",
      "Stockholms",
      "Uppsala",
      "Varmlands",
      "Vasterbottens",
      "Vasternorrlands",
      "Vastmanlands",
      "Vastra Gotalands",
    ],
    phCode: "+46",
  },
  {
    country: "Switzerland",
    code: "CH",
    states: [
      "Aargau",
      "Appenzell Ausser-Rhoden",
      "Appenzell Inner-Rhoden",
      "Basel-Landschaft",
      "Basel-Stadt",
      "Bern",
      "Fribourg",
      "Geneve",
      "Glarus",
      "Graubunden",
      "Jura",
      "Luzern",
      "Neuchatel",
      "Nidwalden",
      "Obwalden",
      "Sankt Gallen",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Zug",
      "Zurich",
    ],
    phCode: "+41",
  },
  {
    country: "Taiwan",
    code: "TW",
    states: [
      "Chang-hua",
      "Chia-i",
      "Hsin-chu",
      "Hua-lien",
      "I-lan",
      "Kao-hsiung",
      "Kin-men",
      "Lien-chiang",
      "Miao-li",
      "Nan-t'ou",
      "P'eng-hu",
      "P'ing-tung",
      "T'ai-chung",
      "T'ai-nan",
      "T'ai-pei",
      "T'ai-tung",
      "T'ao-yuan",
      "Yun-lin",
      "Chia-i",
      "Chi-lung",
      "Hsin-chu",
      "T'ai-chung",
      "T'ai-nan",
      "Kao-hsiung city",
      "T'ai-pei city",
    ],
    phCode: "+886",
  },
  {
    country: "Tanzania",
    code: "TZ",
    states: [
      "Arusha",
      "Dar es Salaam",
      "Dodoma",
      "Iringa",
      "Kagera",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Manyara",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Pemba North",
      "Pemba South",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Singida",
      "Tabora",
      "Tanga",
      "Zanzibar Central/South",
      "Zanzibar North",
      "Zanzibar Urban/West",
    ],
    phCode: "+255",
  },
  {
    country: "Thailand",
    code: "TH",
    states: [
      "Amnat Charoen",
      "Ang Thong",
      "Buriram",
      "Chachoengsao",
      "Chai Nat",
      "Chaiyaphum",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Krung Thep Mahanakhon",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lop Buri",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lamphu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Phangnga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "Sara Buri",
      "Satun",
      "Sing Buri",
      "Sisaket",
      "Songkhla",
      "Sukhothai",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon",
    ],
    phCode: "+66",
  },
  {
    country: "Togo",
    code: "TG",
    states: ["Kara", "Plateaux", "Savanes", "Centrale", "Maritime"],
    phCode: "+228",
  },
  {
    country: "Trinidad and Tobago",
    code: "TT",
    states: [
      "Couva",
      "Diego Martin",
      "Mayaro",
      "Penal",
      "Princes Town",
      "Sangre Grande",
      "San Juan",
      "Siparia",
      "Tunapuna",
      "Port-of-Spain",
      "San Fernando",
      "Arima",
      "Point Fortin",
      "Chaguanas",
      "Tobago",
    ],
    phCode: "+1868",
  },
  {
    country: "Tunisia",
    code: "TN",
    states: [
      "Ariana (Aryanah)",
      "Beja (Bajah)",
      "Ben Arous (Bin 'Arus)",
      "Bizerte (Banzart)",
      "Gabes (Qabis)",
      "Gafsa (Qafsah)",
      "Jendouba (Jundubah)",
      "Kairouan (Al Qayrawan)",
      "Kasserine (Al Qasrayn)",
      "Kebili (Qibili)",
      "Kef (Al Kaf)",
      "Mahdia (Al Mahdiyah)",
      "Manouba (Manubah)",
      "Medenine (Madanin)",
      "Monastir (Al Munastir)",
      "Nabeul (Nabul)",
      "Sfax (Safaqis)",
      "Sidi Bou Zid (Sidi Bu Zayd)",
      "Siliana (Silyanah)",
      "Sousse (Susah)",
      "Tataouine (Tatawin)",
      "Tozeur (Tawzar)",
      "Tunis",
      "Zaghouan (Zaghwan)",
    ],
    phCode: "+216",
  },
  {
    country: "Turkey",
    code: "TR",
    states: [
      "Adana",
      "Adiyaman",
      "Afyonkarahisar",
      "Agri",
      "Aksaray",
      "Amasya",
      "Ankara",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Aydin",
      "Balikesir",
      "Bartin",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bingol",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "Canakkale",
      "Cankiri",
      "Corum",
      "Denizli",
      "Diyarbakir",
      "Duzce",
      "Edirne",
      "Elazig",
      "Erzincan",
      "Erzurum",
      "Eskisehir",
      "Gaziantep",
      "Giresun",
      "Gumushane",
      "Hakkari",
      "Hatay",
      "Igdir",
      "Isparta",
      "Istanbul",
      "Izmir",
      "Kahramanmaras",
      "Karabuk",
      "Karaman",
      "Kars",
      "Kastamonu",
      "Kayseri",
      "Kilis",
      "Kirikkale",
      "Kirklareli",
      "Kirsehir",
      "Kocaeli",
      "Konya",
      "Kutahya",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mersin",
      "Mugla",
      "Mus",
      "Nevsehir",
      "Nigde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "Sanliurfa",
      "Siirt",
      "Sinop",
      "Sirnak",
      "Sivas",
      "Tekirdag",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "Usak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak",
    ],
    phCode: "+90",
  },
  {
    country: "Turkmenistan",
    code: "TM",
    states: [
      "Ahal Welayaty (Ashgabat)",
      "Balkan Welayaty (Balkanabat)",
      "Dashoguz Welayaty",
      "Lebap Welayaty (Turkmenabat)",
      "Mary Welayaty",
    ],
    phCode: "+993",
  },
  {
    country: "Uganda",
    code: "UG",
    states: [
      "Adjumani",
      "Apac",
      "Arua",
      "Bugiri",
      "Bundibugyo",
      "Bushenyi",
      "Busia",
      "Gulu",
      "Hoima",
      "Iganga",
      "Jinja",
      "Kabale",
      "Kabarole",
      "Kaberamaido",
      "Kalangala",
      "Kampala",
      "Kamuli",
      "Kamwenge",
      "Kanungu",
      "Kapchorwa",
      "Kasese",
      "Katakwi",
      "Kayunga",
      "Kibale",
      "Kiboga",
      "Kisoro",
      "Kitgum",
      "Kotido",
      "Kumi",
      "Kyenjojo",
      "Lira",
      "Luwero",
      "Masaka",
      "Masindi",
      "Mayuge",
      "Mbale",
      "Mbarara",
      "Moroto",
      "Moyo",
      "Mpigi",
      "Mubende",
      "Mukono",
      "Nakapiripirit",
      "Nakasongola",
      "Nebbi",
      "Ntungamo",
      "Pader",
      "Pallisa",
      "Rakai",
      "Rukungiri",
      "Sembabule",
      "Sironko",
      "Soroti",
      "Tororo",
      "Wakiso",
      "Yumbe",
    ],
    phCode: "+256",
  },
  {
    country: "Ukraine",
    code: "UA",
    states: [
      "Автономна Республіка Крим",
      "Вінницька область",
      "Волинська область",
      "Дніпропетровська область",
      "Донецька область",
      "Житомирська область",
      "Закарпатська область",
      "Запорізька область",
      "Івано-Франківська область",
      "Київ",
      "Київська область",
      "Кіровоградська область",
      "Луганська область",
      "Львівська область",
      "Миколаївська область",
      "Одеська область",
      "Полтавська область",
      "Рівненська область",
      "Севастополь",
      "Сумська область",
      "Тернопільська область",
      "Харківська область",
      "Херсонська область",
      "Хмельницька область",
      "Черкаська область",
      "Чернівецька область",
      "Чернігівська область",
    ],
    phCode: "+380",
  },
  {
    country: "United Arab Emirates",
    code: "AE",
    states: [
      "أبو ظبي",
      "الشارقة",
      "الفجيرة",
      "ام القيوين",
      "دبي",
      "رأس الخيمة",
      "عجمان",
    ],
    phCode: "+971",
  },
  {
    country: "United Kingdom",
    code: "GB",
    states: [
      "Aberconwy and Colwyn",
      "Aberdeen City",
      "Aberdeenshire",
      "Anglesey",
      "Angus",
      "Antrim",
      "Argyll and Bute",
      "Armagh",
      "Avon",
      "Ayrshire",
      "Bath and NE Somerset",
      "Bedfordshire",
      "Belfast",
      "Berkshire",
      "Berwickshire",
      "BFPO",
      "Blaenau Gwent",
      "Buckinghamshire",
      "Caernarfonshire",
      "Caerphilly",
      "Caithness",
      "Cambridgeshire",
      "Cardiff",
      "Cardiganshire",
      "Carmarthenshire",
      "Ceredigion",
      "Channel Islands",
      "Cheshire",
      "City of Bristol",
      "Clackmannanshire",
      "Clwyd",
      "Conwy",
      "Cornwall/Scilly",
      "Cumbria",
      "Denbighshire",
      "Derbyshire",
      "Derry/Londonderry",
      "Devon",
      "Dorset",
      "Down",
      "Dumfries and Galloway",
      "Dunbartonshire",
      "Dundee",
      "Durham",
      "Dyfed",
      "East Ayrshire",
      "East Dunbartonshire",
      "East Lothian",
      "East Renfrewshire",
      "East Riding Yorkshire",
      "East Sussex",
      "Edinburgh",
      "England",
      "Essex",
      "Falkirk",
      "Fermanagh",
      "Fife",
      "Flintshire",
      "Glasgow",
      "Gloucestershire",
      "Greater London",
      "Greater Manchester",
      "Gwent",
      "Gwynedd",
      "Hampshire",
      "Hartlepool",
      "Hereford and Worcester",
      "Hertfordshire",
      "Highlands",
      "Inverclyde",
      "Inverness-Shire",
      "Isle of Man",
      "Isle of Wight",
      "Kent",
      "Kincardinshire",
      "Kingston Upon Hull",
      "Kinross-Shire",
      "Kirklees",
      "Lanarkshire",
      "Lancashire",
      "Leicestershire",
      "Lincolnshire",
      "Londonderry",
      "Merseyside",
      "Merthyr Tydfil",
      "Mid Glamorgan",
      "Mid Lothian",
      "Middlesex",
      "Monmouthshire",
      "Moray",
      "Neath & Port Talbot",
      "Newport",
      "Norfolk",
      "North Ayrshire",
      "North East Lincolnshire",
      "North Lanarkshire",
      "North Lincolnshire",
      "North Somerset",
      "North Yorkshire",
      "Northamptonshire",
      "Northern Ireland",
      "Northumberland",
      "Nottinghamshire",
      "Orkney and Shetland Isles",
      "Oxfordshire",
      "Pembrokeshire",
      "Perth and Kinross",
      "Powys",
      "Redcar and Cleveland",
      "Renfrewshire",
      "Rhonda Cynon Taff",
      "Rutland",
      "Scottish Borders",
      "Shetland",
      "Shropshire",
      "Somerset",
      "South Ayrshire",
      "South Glamorgan",
      "South Gloucesteshire",
      "South Lanarkshire",
      "South Yorkshire",
      "Staffordshire",
      "Stirling",
      "Stockton On Tees",
      "Suffolk",
      "Surrey",
      "Swansea",
      "Torfaen",
      "Tyne and Wear",
      "Tyrone",
      "Vale Of Glamorgan",
      "Wales",
      "Warwickshire",
      "West Berkshire",
      "West Dunbartonshire",
      "West Glamorgan",
      "West Lothian",
      "West Midlands",
      "West Sussex",
      "West Yorkshire",
      "Western Isles",
      "Wiltshire",
      "Wirral",
      "Worcestershire",
      "Wrexham",
      "York",
    ],
    phCode: "+44",
  },
  {
    country: "United States",
    code: "US",
    states: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "Armed Forces (AA)",
      "Armed Forces (AE)",
      "Armed Forces (AP)",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Micronesia",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Islands",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    phCode: "+1",
  },
  {
    country: "Uruguay",
    code: "UY",
    states: [
      "Artigas",
      "Canelones",
      "Cerro Largo",
      "Colonia",
      "Durazno",
      "Flores",
      "Florida",
      "Lavalleja",
      "Maldonado",
      "Montevideo",
      "Paysandu",
      "Rio Negro",
      "Rivera",
      "Rocha",
      "Salto",
      "San Jose",
      "Soriano",
      "Tacuarembo",
      "Treinta y Tres",
    ],
    phCode: "+598",
  },
  {
    country: "Uzbekistan",
    code: "UZ",
    states: [
      "Andijon Viloyati",
      "Buxoro Viloyati",
      "Farg'ona Viloyati",
      "Jizzax Viloyati",
      "Namangan Viloyati",
      "Navoiy Viloyati",
      "Qashqadaryo Viloyati",
      "Qaraqalpog'iston Respublikasi",
      "Samarqand Viloyati",
      "Sirdaryo Viloyati",
      "Surxondaryo Viloyati",
      "Toshkent Shahri",
      "Toshkent Viloyati",
      "Xorazm Viloyati",
    ],
    phCode: "+998",
  },
  {
    country: "Vanuatu",
    code: "VU",
    states: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
    phCode: "+678",
  },
  {
    country: "Venezuela",
    code: "VE",
    states: [
      "Amazonas",
      "Anzoategui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolivar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Dependencias Federales",
      "Distrito Federal",
      "Falcon",
      "Guarico",
      "Lara",
      "Merida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Tachira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia",
    ],
    phCode: "+58",
  },
  {
    country: "Vietnam",
    code: "VN",
    states: [
      "An Giang",
      "Bac Giang",
      "Bac Kan",
      "Bac Lieu",
      "Bac Ninh",
      "Ba Ria-Vung Tau",
      "Ben Tre",
      "Binh Dinh",
      "Binh Duong",
      "Binh Phuoc",
      "Binh Thuan",
      "Ca Mau",
      "Cao Bang",
      "Dac Lak",
      "Dac Nong",
      "Dien Bien",
      "Dong Nai",
      "Dong Thap",
      "Gia Lai",
      "Ha Giang",
      "Hai Duong",
      "Ha Nam",
      "Ha Tay",
      "Ha Tinh",
      "Hau Giang",
      "Hoa Binh",
      "Hung Yen",
      "Khanh Hoa",
      "Kien Giang",
      "Kon Tum",
      "Lai Chau",
      "Lam Dong",
      "Lang Son",
      "Lao Cai",
      "Long An",
      "Nam Dinh",
      "Nghe An",
      "Ninh Binh",
      "Ninh Thuan",
      "Phu Tho",
      "Phu Yen",
      "Quang Binh",
      "Quang Nam",
      "Quang Ngai",
      "Quang Ninh",
      "Quang Tri",
      "Soc Trang",
      "Son La",
      "Tay Ninh",
      "Thai Binh",
      "Thai Nguyen",
      "Thanh Hoa",
      "Thua Thien-Hue",
      "Tien Giang",
      "Tra Vinh",
      "Tuyen Quang",
      "Vinh Long",
      "Vinh Phuc",
      "Yen Bai",
      "Can Tho",
      "Da Nang",
      "Hai Phong",
      "Hanoi",
      "Ho Chi Minh",
    ],
    phCode: "+84",
  },
  {
    country: "Zambia",
    code: "ZM",
    states: [
      "Central",
      "Copperbelt",
      "Eastern",
      "Luapula",
      "Lusaka",
      "Northern",
      "North-Western",
      "Southern",
      "Western",
    ],
    phCode: "+260",
  },
  {
    country: "Zimbabwe",
    code: "ZW",
    states: [
      "Bulawayo",
      "Harare",
      "Manicaland",
      "Mashonaland Central",
      "Mashonaland East",
      "Mashonaland West",
      "Masvingo",
      "Matabeleland North",
      "Matabeleland South",
      "Midlands",
    ],
    phCode: "+263",
  },
]

export default countriesWithStates
