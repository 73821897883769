import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import storage from "../../../utils/local-storage"
import * as yup from "yup"

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must meet complexity requirements"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
})

const PasswordInfo = ({ onConfirm, onPrev, currentStepCount }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = data => {
    if (isValid) {
      onConfirm(currentStepCount + 1, data.password)
    }
  }

  return (
    <div>
      <p className="mb-2">Choose password for your account</p>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          placeholder="Password"
          type={"password"}
          {...register("password")}
        />
        <p className={"error-text"}>{errors.password?.message}</p>

        <div className="pb-4" />

        <input
          placeholder="Confirm Password"
          type={"password"}
          {...register("confirm_password")}
        />
        <p className={"error-text"}>{errors.confirm_password?.message}</p>
        <div className="pb-4" />

        <h5>Password must contain the following:</h5>
        <ul className="password-cat list-disc">
          <li>Minimum 8 characters</li>
          <li>A special character (!@#$%^&*)</li>
          <li>A capital (uppercase) letter (A-Z)</li>
          <li>A number (0-9)</li>
        </ul>

        <div className="pb-4" />
        <div className="text-center mb-2">
          <button onClick={() => onPrev(currentStepCount - 1)}>Previous</button>
          <span className="px-1" />
          <button style={{ backgroundColor: "#001a1c" }} type="submit">
            &nbsp; Next &nbsp;
          </button>
        </div>
      </form>
    </div>
  )
}

export default React.memo(PasswordInfo)
