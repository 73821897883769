import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import storage from "../../../utils/local-storage"
import { MAX_NAME_LENGTH } from "../../../utils/Constants"
import countriesWithStates from "../../../utils/county-with-states"

const basicInformationValidationSchema = yup.object({
  title: yup.string().required("Title is required"),
  first_name: yup
    .string()
    .required("First Name is required")
    .min(MAX_NAME_LENGTH, `Must be ${MAX_NAME_LENGTH} characters long`),
  last_name: yup
    .string()
    .required("Last Name is required")
    .min(MAX_NAME_LENGTH, `Must be ${MAX_NAME_LENGTH} characters long`),
  email: yup.string().email().required("Email is required"),
  gender: yup.string().required("Gender is required"),
  phone: yup.string().required("Phone is required"),
})

const BasicInformation = ({
  onConfirm,
  onPrev,
  currentStepCount,
  email = null,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      ...storage.getUserInfo(storage.USER_KEY),
      email: email,
    },
    resolver: yupResolver(basicInformationValidationSchema),
  })

  const onSubmit = data => {
    storage.setUserInfo(storage.USER_KEY, data)
    onConfirm(currentStepCount + 1)
  }

  return (
    <div>
      <p className="mb-4">Please enter your name and contact information</p>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex mb-4" style={{ gap: "8px" }}>
          <div style={{ flex: 1 }}>
            <select {...register("title")}>
              <option value="" disabled>
                Title
              </option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Dr.">Dr.</option>
              <option value="Miss">Miss</option>
            </select>
            <p className={"error-text"}>{errors.title?.message}</p>
          </div>

          <div style={{ flex: 2 }}>
            <input
              placeholder="First Name"
              type={"text"}
              {...register("first_name")}
            />
            <p className={"error-text"}>{errors.first_name?.message}</p>
          </div>

          <div style={{ flex: 2 }}>
            <input
              placeholder="Last Name"
              type={"text"}
              {...register("last_name")}
            />
            <p className={"error-text"}>{errors.last_name?.message}</p>
          </div>
        </div>
        <small style={{ color: "rgb(67, 172, 204)", fontWeight: "bold" }}>
          *** Your email address will be used as your username.
        </small>
        <input
          placeholder="Email"
          type={"text"}
          {...register("email")}
          readOnly={email !== null && email !== undefined && email !== ""}
        />
        <p className={"error-text"}>{errors.email?.message}</p>
        <div className="pb-4" />
        <select {...register("gender")} defaultValue="">
          <option value="" disabled>
            Select gender...
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        <p className={"error-text"}>{errors?.gender?.message}</p>
        <div className="pb-4" />
        <select {...register("country_code")} defaultValue="">
          <option key={`country_code-0`} value="" disabled>
            Country Code...
          </option>
          {countriesWithStates.map((obj, index) => {
            return (
              <option key={`country_code-${index + 1}`} value={obj.phCode}>
                {obj.country} {obj.phCode}
              </option>
            )
          })}
        </select>
        <p className={"error-text"}>{errors.country_code?.message}</p>
        <div className="pb-4" />
        <input placeholder="Phone" type={"number"} {...register("phone")} />
        <p className={"error-text"}>{errors.phone?.message}</p>
        <div className="pb-4" />
        <div className="pb-4" />
        <div className="text-right mb-2">
          <button onClick={() => onPrev(currentStepCount - 1)}>Previous</button>
          <span className="px-1" />
          <button style={{ backgroundColor: "#001a1c" }} type="submit">
            &nbsp; &nbsp; Next &nbsp; &nbsp;
          </button>
        </div>
      </form>
    </div>
  )
}

export default React.memo(BasicInformation)
